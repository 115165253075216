import {Injectable, OnDestroy} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {ManageService} from '../manage.service';
import {FormService} from '../form/form.service';
import {OrderDto} from '@app/core/resource-dto/order/order';
import {ToastrService} from 'ngx-toastr';
import {distinctUntilChanged, filter, map, shareReplay, takeUntil} from 'rxjs/operators';
import {combineLatest, ReplaySubject, Subject, Subscription} from 'rxjs';
import {ReCaptchaV3Service} from 'ng-recaptcha';
import {HttpEventType} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';

class Actions {
    save: boolean;
    accept: boolean;
}

@Injectable()
export class CreateQuestService implements OnDestroy {

    private languageSubscription: Subscription;
    private routerSubscription: Subscription;

    public actions$ = combineLatest([
        this.manageService.order$.pipe(distinctUntilChanged())
    ]).pipe(
        distinctUntilChanged(),
        map(CreateQuestService.updateActions),
        shareReplay(1)
    );

    public progress = false;
    public showFormErrors = false;
    public formSent = false;
    public orderForm = this.formService.orderForm;

    private readonly isMinistryPageSource = new ReplaySubject<boolean>(1);
    public readonly isMinistryPage$ = this.isMinistryPageSource.asObservable();
    private isMinistryPage = false;

    public readonly formErrors = {
        requesterName: null as any,
        requesterEmail: null as any,
        requesterPhone: null as any,
        building: null as any,
        description: null as any,
        atLeastOne: null as any
    };

    public readonly validationMessages = {
        requesterName: {
            required: 'Palun sisesta taotleja nimi.',
        },
        requesterEmail: {
            email: 'Palun sisesta korrektne e-posti aadress.'
        },
        building: {
            required: 'Palun vali hoone.'
        },
        description: {
            required: 'Probleemi kirjeldus peab olema täidetud.'
        },
        requesterPhone: {
            required: 'Palun sisesta telefoninumber.',
            pattern: 'Telefoninumber ei ole õigel kujul.'
        },
        atLeastOne: 'Palun sisesta taotleja e-posti aadress või telefon.',
    };

    private readonly ngDestroy = new Subject<void>();

    private translatedTexts = {};

    private static updateActions([order]: [OrderDto.Order]) {
        const actions = new Actions();
        if (!order) return actions;

        actions.save = true;

        return actions;
    }

    public constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly manageService: ManageService,
        private readonly formService: FormService,
        private readonly toastr: ToastrService,
        private readonly reCaptchaV3Service: ReCaptchaV3Service,
        private readonly translate: TranslateService
    ) {
        this.setupRouting();
        this.setupOrderForm();
    }

    public ngOnDestroy(): void {
        this.ngDestroy.next();
        this.ngDestroy.complete();

        if (this.languageSubscription) {
            this.languageSubscription.unsubscribe();
        }

        if (this.routerSubscription) {
            this.routerSubscription.unsubscribe();
        }
    }

    public getTranslatedText(key: string) {
        return this.translatedTexts[key] !== 'undefined' ? this.translatedTexts[key] : key;
    }

    private onFormValueChanged(): void {
        if (!this.orderForm) return;

        const form = this.orderForm;
        for (const field in this.formErrors) {
            this.formErrors[field] = null;
            const control = form.get(field);

            if (control && (control.dirty) && !control.valid) {
                const messages = this.validationMessages[field];

                this.formErrors[field] = control.errors;

                for (const key in control.errors) {
                    if (this.formErrors[field][key]) {
                        this.formErrors[field][key] = messages[key];
                    }
                }
            }
        }
        if (this.orderForm.errors && this.orderForm.errors.atLeastOne) {
            this.formErrors.atLeastOne = this.validationMessages.atLeastOne;
        }

        if (this.orderForm.valid) {
            this.showFormErrors = false;
        }
    }

    public save(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            if (this.progress) return resolve(false);

            if (this.validateOrderForm()) {
                this.formService.updateInvalidOrderShareMap(new Map());
                resolve(true);
                this.progress = true;

                this.reCaptchaV3Service.execute('savePublicOrder').subscribe(token => {
                        const order: FormData = this.prepareOrderInputDtoForSubmitWithToken(token);
                        this.manageService.savePublicOrder(order).subscribe(
                            (event) => {
                                if (event.type === HttpEventType.Response && event.status === 201) {
                                    this.formSent = true;
                                    this.progress = false;
                                    this.formService.orderForm.reset();
                                }
                            }, (error) => {
                                this.toastr.error(this.getTranslatedText('Taotluse esitamine ebaõnnestus'), this.getTranslatedText('Viga!'));
                                this.progress = false;
                                reject(error);
                            });
                    },
                    (error) => {
                        console.error(error);
                        this.toastr.error(JSON.stringify(error), this.getTranslatedText('Viga!'));
                        this.progress = false;
                        reject(error);
                    });
            } else {
                resolve(this.getTranslatedText('Vigane vorm'));
            }
        });
    }

    public reset(): void {
        this.formService.resetPublicFormValue();
        this.formService.emptyFileList();
        this.showFormErrors = false;
        this.formSent = false;
    }

    private prepareOrderInputDtoForSubmitWithToken(token: string): FormData {
        const formModel = this.orderForm.getRawValue();

        const order = new FormData();

        order.append('token', token);
        if (this.isMinistryPage) {
            order.append('ministryForm', '1');
        } else {
            order.append('requesterName', formModel.requesterName);
            order.append('requesterEmail', formModel.requesterEmail);
            order.append('requesterPhone', formModel.requesterPhone);
            order.append('ministryForm', 'false');
        }
        order.append('location', formModel.location);

        order.append('buildingId', formModel.building ? formModel.building.id : null);

        order.append('description', formModel.description.replace(/  +/g, ' ').trim());

        for (const fileList of this.formService.filesArray.value) {
            order.append('files', fileList.fileRef.file);
        }
        return order;
    }

    private validateOrderForm(): boolean {
        this.orderForm.markAsTouched({onlySelf: true});
        this.orderForm.markAsDirty({onlySelf: true});
        Object.keys(this.orderForm.controls).map((controlName) => {
            this.orderForm.get(controlName).markAsTouched({onlySelf: true});
            this.orderForm.get(controlName).markAsDirty({onlySelf: true});
        });
        this.orderForm.updateValueAndValidity({onlySelf: false});

        if (this.orderForm.valid) {
            return true;
        } else {
            this.showFormErrors = true;
            window.scrollTo(0, 0);

            return false;
        }
    }

    private setupOrderForm() {
        this.orderForm.valueChanges.pipe(takeUntil(this.ngDestroy)).subscribe(__ => this.onFormValueChanged());
    }

    private setupRouting(): void {
        this.translate.setDefaultLang('et');
        this.updatePage();
        this.updateLanguage();
    }

    private updatePage(): void {
        this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            takeUntil(this.ngDestroy)
        ).subscribe(_ => {
            const child = this.route.firstChild?.snapshot.data;
            this.isMinistryPage = (child && child.ministry) ? !!child.ministry : false;
            this.isMinistryPageSource.next(this.isMinistryPage);
        });
    }

    private updateLanguage(): void {
        this.routerSubscription = this.route.data.subscribe(next => {
            const language = (next.lang && next.lang.length === 2) ? next.lang : 'et';

            if (this.languageSubscription) {
                this.languageSubscription.unsubscribe();
            }

            this.languageSubscription = this.translate.use(language).subscribe(translations => {
                    this.validationMessages.requesterName = translations['Palun sisesta taotleja nimi.'];
                    this.validationMessages.requesterEmail.email = translations['Palun sisesta korrektne e-posti aadress.'];
                    this.validationMessages.building.required = translations['Palun vali hoone.'];
                    this.validationMessages.description.required = translations['Probleemi kirjeldus peab olema täidetud.'];
                    this.validationMessages.atLeastOne = translations['Palun sisesta taotleja e-posti aadress või telefon.'];
                    this.validationMessages.requesterPhone.required = translations['Palun sisesta telefoninumber.'];
                    this.validationMessages.requesterPhone.pattern = translations['Telefoninumber ei ole õigel kujul.'];
                    this.translatedTexts = {};
                    this.translatedTexts['Viga!'] = translations['Viga!'];
                    this.translatedTexts['Vigane vorm'] = translations['Vigane vorm'];
                    this.translatedTexts['Taotluse esitamine ebaõnnestus!'] = translations['Taotluse esitamine ebaõnnestus!'];
                }
            );
        });
    }
}
