<div class="works-link"
     [class.disabled]="disabled"
     [class.active]="!!innerFormControl ? innerFormControl.value : state"
     [ngClass]="type"
     [tooltip]="tooltipContent"
     (click)="onClick()">
    <span class="works-link-label text-sm text-nowrap d-flex align-items-center">
        <ng-content></ng-content>
    </span>
    <h4 *ngIf="hasCount" class="works-filtered-number">
        {{count}}
    </h4>
</div>
