import {Component, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {AuthService} from "@app/core/services/auth.service";
import {UserLoginDto} from "@app/core/resource-dto/user-login";
import UserCompany = UserLoginDto.UserCompany;

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'representative-select',
  templateUrl: './representative-select.component.html',
  // tslint:disable-next-line:no-host-metadata-property
  host: {class: 'dropdown user-menu'}
})
export class RepresentativeSelectComponent implements OnDestroy {

    private readonly ngDestroy: Subject<void> = new Subject<void>();
    public currentUser: UserLoginDto.User = null;
    public currentUserCompany: UserLoginDto.UserCompany = null;

    constructor(private authService: AuthService) {
        this.authService.user$.pipe(takeUntil(this.ngDestroy)).subscribe(user => this.currentUser = user);

        this.authService.userCompany$.pipe(takeUntil(this.ngDestroy)).subscribe(_ => {
            this.currentUserCompany = this.authService.getCurrentUserCompany();
        });
    }

    public ngOnDestroy(): void {
        this.ngDestroy.next();
        this.ngDestroy.complete();
    }

    public selectCompany(userCompany: UserCompany): void {
        this.authService.doUserCompanyLogin(userCompany);
    }

    public btnLogoutClicked(): void {
        this.authService.redirectToCasLogout();
    }
}
