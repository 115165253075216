import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {DeviceStateService} from '@app/core/services/device';

@Component({
    selector: 'app-badge',
    templateUrl: './badge.component.html'
})
export class BadgeComponent implements OnChanges {

    private static readonly VERIFIED_STATUSES = ['ORST_DONE', 'ORST_APPROVED', 'ORST_CLOSED'];

    @Input()
    public classifier: string;

    @Input()
    public value;

    @Input()
    public badgeClasses: string[] = [];

    @Input()
    public verifiedByClient = null;

    public readonly isMobileView$ = this.deviceStateService.isMobileView$;
    public classes = [];

    public constructor(private readonly deviceStateService: DeviceStateService) {}

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.value || changes.badgeClasses) {
            if (!this.badgeClasses) {
                this.badgeClasses = [];
            }
            let baseClass = 'badge-' + this.value?.toLowerCase().replace(/_/g, '-');

            if (this.value) {
                if (this.isNotVerifiedByClient()) {
                    baseClass += ' badge-not-verified';
                } else if (!!this.verifiedByClient && BadgeComponent.VERIFIED_STATUSES.includes(this.value)) {
                    baseClass += ' badge-verified';
                }
            }
            if (baseClass) {
                this.classes = [baseClass].concat(this.badgeClasses.map((c) => 'badge-' + c));
            } else {
                this.classes = this.badgeClasses.map((c) => 'badge-' + c);
            }
        }
    }

    public isNotVerifiedByClient(): boolean {
        return BadgeComponent.VERIFIED_STATUSES.includes(this.value) && this.verifiedByClient === false;
    }
}
