<nav *ngIf="isVisible$ | async"
     class="navbar navbar-mobile-breadcrumbs">
    <ul>
        <li class="nav-breadcrumb">
            <a [routerLink]="['/']"
               type="button"
               class="nav-link">
                Avaleht
            </a>
            <span class="icon icon_arrow gray"></span>
        </li>
        <li class="nav-breadcrumb" *ngFor="let breadcrumb of (breadcrumbs$ | async | slice:0:-1); let i = index">
            <a [routerLink]="breadcrumb.path"
               [queryParams]="queryParams$ | async"
               type="button"
               class="nav-link">
                {{breadcrumb.name$ | async}}
            </a>
            <span class="icon icon_arrow gray"></span>
        </li>
        <li class="nav-breadcrumb">
            <span class="nav-link nav-link-current">{{(breadcrumbs$ | async | slice:-1)[0]?.name$ | async}}</span>
        </li>
    </ul>
</nav>
