import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-frequency-badge',
  templateUrl: './frequency-badge.component.html',
  styleUrls: ['./frequency-badge.component.scss']
})
export class FrequencyBadgeComponent implements OnChanges {

  @Input()
  public frequency: number;

  @Input()
  public classifier: string;

  @Input()
  public value;

  @Input()
  public badgeClasses: string[] = [];

  public classes = [];

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.value || changes.badgeClasses) {
      if (!this.badgeClasses) {
        this.badgeClasses = [];
      }
      let baseClass: string = null;
      if (this.value) {
        baseClass = 'badge-' + this.value.toLowerCase().replace(/_/g, '-');
      }
      if (baseClass) {
        this.classes = [baseClass].concat(this.badgeClasses.map((c) => 'badge-' + c));
      } else {
        this.classes = this.badgeClasses.map((c) => 'badge-' + c);
      }
    }
  }
}
