import {Injectable, TemplateRef} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router} from '@angular/router';
import {filter, map} from 'rxjs/operators';
import {BehaviorSubject, Observable, ReplaySubject} from 'rxjs';
import {Moment} from 'moment';
import {BadgeState} from './navbar-badge/badge-state';
import {MonthParams} from './month-params.interface';
import {RangeSelection} from '@app/appliance/common/range-table';
import {DeviceInformationUtils} from '@app/core/utils';
import {TimerService} from '@app/core/services/timer.service';
import {Breadcrumb} from './breadcrumbs/breadcrumb';

@Injectable({providedIn: 'root'})
export class NavbarService {

    private readonly badgesSource = new ReplaySubject<BadgeState>();
    private readonly actionsTemplateSource = new ReplaySubject<TemplateRef<any>>(1);
    private readonly footerTemplateSource = new ReplaySubject<TemplateRef<any>>(1);
    private readonly breadcrumbsSource = new BehaviorSubject<any[]>([]);
    private readonly breadcrumbSource = new BehaviorSubject<Breadcrumb | undefined>(undefined);

    public readonly actionsTemplate$ = this.actionsTemplateSource.asObservable();
    public readonly footerTemplate$ = this.footerTemplateSource.asObservable();
    public readonly breadcrumbs$ = this.breadcrumbsSource.asObservable();
    public readonly breadCrumb$ = this.breadcrumbSource.asObservable();
    public readonly badges$ = this.badgesSource.asObservable();
    private readonly badgeState = new BadgeState();
    public readonly contractPermissionList = [
        'maintenance-contract.access.all-contracts',
        'maintenance-contract.access.company-contracts',
        'maintenance-contract.access.property-contracts',
        'maintenance-contract.access.regional-property-contracts',
        'access.records-contractor-project-manager',
        'access.records-contractor-dispatcher',
        'access.records-contractor-works-manager',
        'access.records-rkik',
        'access.records-prison-tallinn',
        'access.records-prison-tartu',
        'access.records-prison-viru',
        'access.records-all-except-prison',
        'th.access.records-north',
        'th.access.records-south',
        'th.access.records-east',
        'th.access.records-west',
        'hk.access.records-north',
        'hk.access.records-south',
        'hk.access.records-east',
        'hk.access.records-west',
        'tt.access.records-north',
        'tt.access.records-south',
        'tt.access.records-east',
        'tt.access.records-west',
        'construction-contract.access'
    ];

    public readonly scheduleRangeParams$ = this.timerService.monthTick$
        .pipe(map(month => this.toMonthParams(month.clone().add(1, 'month'))));
    private readonly currentMonthParams$ = this.timerService.monthTick$.pipe(map(month => this.toMonthParams(month)));
    private readonly currentWeekParams$ = this.timerService.weekTick$.pipe(map(week => this.toWeekParams(week)));
    public readonly planRangeParams$: Observable<any> = DeviceInformationUtils.getDeviceType() === 'mobile' ?
        this.currentWeekParams$ : this.currentMonthParams$;

    public constructor(
        private readonly timerService: TimerService,
        private readonly router: Router,
        private readonly route: ActivatedRoute
    ) {
        this.listenToRouterEvents();
    }

    public setActionsTemplate(template: TemplateRef<any>) {
        this.actionsTemplateSource.next(template);
    }

    public setFooterTemplate(template: TemplateRef<any>) {
        this.footerTemplateSource.next(template);
    }

    private listenToRouterEvents(): void {
        this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(this.generateBreadcrumbs.bind(this));
    }

    private generateBreadcrumbs(): void {
        const breadcrumbs: Breadcrumb[] = [];
        let actionsComponent = null;

        let route = this.route.snapshot;

        while (route?.outlet === 'primary') {
            let next = null;
            if (this.hasBreadcrumb(route) && !breadcrumbs.includes(route.data.breadcrumb)) {
                route.data.breadcrumb.path = this.getFullPath(route);
                breadcrumbs.push(route.data.breadcrumb);
            }

            if (route.data.navbarActionsComponent) {
                actionsComponent = route.data.navbarActionsComponent;
            }

            route.children.forEach(child => {
                if (child.outlet === 'primary') {
                    next = child;
                }
            });

            route = next;
        }

        this.breadcrumbSource.next(breadcrumbs.length > 0 ? breadcrumbs[breadcrumbs.length - 1] : undefined);
        this.breadcrumbsSource.next(breadcrumbs);
    }

    public update(acts: number, inspections: number, submittedOverdue: number, messages: number): void {
        this.badgeState.inspections = inspections;
        this.badgeState.inspectionClass = submittedOverdue > 0 ? 'badge-danger' : 'badge-success';
        this.badgeState.acts = acts;
        this.badgeState.messages = messages;
        this.badgeState.messagesClass = messages > 0 ? 'badge-danger' : '';
        this.badgesSource.next(this.badgeState);
    }

    private getFullPath(route: ActivatedRouteSnapshot): string[] {
        const output = [];
        let current = route;
        while (!!current) {
            const segments = current.url.map(e => e.path);
            segments.reverse().forEach(segment => output.unshift(segment));
            current = current.parent;
        }

        return output;
    }

    private toMonthParams(aMoment: Moment): MonthParams {
        return {
            year: aMoment.year(),
            month: aMoment.month() + 1
        };
    }

    private toWeekParams(input: Moment): RangeSelection | undefined {
        if (!input?.isValid()) {
            return undefined;
        }

        return {
            year: input.year(),
            week: input.week()
        };
    }

    private hasBreadcrumb(route: ActivatedRouteSnapshot): boolean {
        if (!route?.data?.breadcrumb) return false;

        return route.data.breadcrumb instanceof Breadcrumb;
    }
}
