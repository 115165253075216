import {MessageDto} from '@app/core/resource-dto/messages';
import Message = MessageDto.Message;
import MessageType = MessageDto.MessageType;
import {MetadataDto} from '@app/core/resource-dto/metadata';
import IdCodeAndName = MetadataDto.IdCodeAndName;

export class MessagesRowModel {

  public readonly id: number;
  public readonly title: string;
  public readonly text: string;
  public readonly type: MessageType;
  public readonly createdBy: string;
  public readonly createdAt: string;
  public readonly isPublished: boolean;
  public readonly roles: IdCodeAndName[];

  public static of(row: Message) {
    return new MessagesRowModel(row);
  }

  private constructor(row?: Message) {
    if (!row) return;

    this.id = row.id;
    this.title = row.title;
    this.text = row.text;
    this.type = row.type;
    this.createdBy = row.createdBy.name;
    this.createdAt = row.createdAt;
    this.isPublished = row.isPublished;
    this.roles = row.roles;
  }
}
