import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-due-date-badge',
  templateUrl: './due-date-badge.component.html'
})
export class DueDateBadgeComponent {

  @Input() isAccident: boolean;
  @Input() isRepair: boolean;
  @Input() isOverdue: boolean;
  @Input() overdueDays: number;
  @Input() overdueHours: number;

  @Input() value: number;
}
