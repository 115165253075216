import {Component, Input, OnDestroy} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {BehaviorSubject, combineLatest, Subject} from 'rxjs';
import {distinctUntilChanged, map, takeUntil} from 'rxjs/operators';
import {NavbarService} from '@app/core/components/navbar/navbar.service';
import {MobileBreadcrumbsHelper} from './mobile-breadcrumbs.helper';
import {DeviceStateService} from '@app/core/services/device';

@Component({
    selector: 'app-mobile-breadcrumbs',
    templateUrl: './mobile-breadcrumbs.component.html'
})
export class MobileBreadcrumbsComponent implements OnDestroy {

    @Input()
    public set isAppLevel(value: boolean) {
        this.isAppLevelSource.next(!!value);
    }

    private readonly ngDestroy = new Subject<void>();

    public readonly breadcrumbs$ = this.navbarService.breadcrumbs$;
    public readonly queryParams$ = this.route.queryParams;

    private readonly isAppLevelSource = new BehaviorSubject<boolean>(false);
    private readonly isAppLevel$ = this.isAppLevelSource.asObservable().pipe(takeUntil(this.ngDestroy), distinctUntilChanged());
    private readonly breadcrumb$ = this.navbarService.breadCrumb$.pipe(takeUntil(this.ngDestroy), distinctUntilChanged());
    private readonly isMobileView$ = this.deviceStateService.isMobileView$.pipe(takeUntil(this.ngDestroy), distinctUntilChanged());
    public readonly isVisible$ = combineLatest([this.isAppLevel$, this.isMobileView$, this.breadcrumb$]).pipe(
        takeUntil(this.ngDestroy),
        map(([isAppLevel, isMobileView, breadcrumb]) => MobileBreadcrumbsHelper.isVisible(isAppLevel, isMobileView, breadcrumb)),
        distinctUntilChanged()
    );

    public constructor(
        private readonly navbarService: NavbarService,
        private readonly deviceStateService: DeviceStateService,
        private readonly route: ActivatedRoute
    ) {}

    public ngOnDestroy(): void {
        this.ngDestroy.next();
        this.ngDestroy.complete();
    }
}
