import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {LetContext} from './let-context.interface';

// tslint:disable-next-line:directive-selector
@Directive({selector: '[ngLet]'})
export class LetDirective<T> {

    private readonly context: LetContext<T> = {ngLet: undefined};

    public constructor(
        readonly viewContainer: ViewContainerRef,
        readonly templateRef: TemplateRef<LetContext<T>>
    ) {
        viewContainer.createEmbeddedView(templateRef, this.context);
    }

    @Input()
    public set ngLet(value: T) {
        this.context.ngLet = value;
    }
}
