import '@app/shared/interfaces/string-interface';
import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {CoreModule} from './core/core.module';
import {ToastrModule} from 'ngx-toastr';
import {environment} from '../environments/environment';
import {WORKS_CORE_CONFIG} from './core/injection-tokens';
import {CommonModule} from '@angular/common';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {ResourceModule} from '@ngx-resource/handler-ngx-http';
import {NgxPermissionsModule} from 'ngx-permissions';
import {appInitializerFactory} from './app-initializer';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatomoModule} from 'ngx-matomo';
import {NgbDateAdapter, NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import {
    MOMENT_NGB_DATE_FORMAT,
    MomentNgbDateParserFormatter
} from '@app/shared/services/moment-ngb-date-parser-formatter';
import {MomentNgbDateAdapter} from '@app/shared/services/moment-ngb-date-adapter.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {UiSwitchModule} from 'ngx-ui-switch';
import {GDPRGuard} from '@app/shared/guards/gdpr.guard';
import {ManageService as InspectionManageService} from '@app/inspection/manage/manage.service';
import {RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module} from 'ng-recaptcha';
import {ModalModule} from 'ngx-bootstrap/modal';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {PopoverModule} from 'ngx-bootstrap/popover';
import {ButtonsModule} from 'ngx-bootstrap/buttons';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {TimepickerModule} from 'ngx-bootstrap/timepicker';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {PublicPermissionsService} from '@app/shared/services/public-permission.service';
import {SessionTimeoutService} from '@app/core/services/session-timeout.service';
import {LoggerService} from '@app/core/services/logger.service';
import {ErrorHandlerService} from '@app/core/services/error-handler.service';
import {AuthService} from '@app/core/services/auth.service';
import {PermissionsService} from '@app/shared/services/permissions.service';
import {NgxPrintModule} from 'ngx-print';
import {ContractorPersonAssignService} from '@app/contracts/modals/assign-contractor/contractor-person-assign.service';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        CommonModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserModule,
        HttpClientModule,
        ResourceModule.forRoot(),
        NgxPermissionsModule.forRoot(),
        CoreModule,
        MatomoModule,
        ModalModule.forRoot(),
        BsDropdownModule.forRoot(),
        TooltipModule.forRoot(),
        PopoverModule.forRoot(),
        ButtonsModule.forRoot(),
        BsDatepickerModule.forRoot(),
        TimepickerModule.forRoot(),
        ToastrModule.forRoot({
            positionClass: 'toast-bottom-left',
            preventDuplicates: true
        }),
        UiSwitchModule.forRoot({}),
        AppRoutingModule,
        RecaptchaV3Module,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        NgxPrintModule
    ],
    providers: [
        {
            provide: WORKS_CORE_CONFIG,
            useValue: environment
        },
        {
            provide: LOCALE_ID,
            useValue: 'et'
        },
        {
            provide: RECAPTCHA_V3_SITE_KEY,
            useValue: environment.reCaptchaSiteKey
        },
        {
            provide: ErrorHandler,
            useClass: ErrorHandlerService,
            deps: [LoggerService]
        },
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFactory,
            deps: [PublicPermissionsService, AuthService],
            multi: true
        },
        {
            provide: NgbDateAdapter,
            useClass: MomentNgbDateAdapter
        },
        MomentNgbDateAdapter,
        {
            provide: NgbDateParserFormatter,
            useClass: MomentNgbDateParserFormatter
        },
        {
            provide: MOMENT_NGB_DATE_FORMAT,
            useValue: 'DD.MM.YYYY'
        },
        GDPRGuard,
        LoggerService,
        PermissionsService,
        InspectionManageService,
        SessionTimeoutService,
        ContractorPersonAssignService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
