export class RoundQuickFilterEntry {

    public readonly key!: any;
    public readonly name!: string;
    public readonly active!: boolean;
    public readonly tooltipContent?: string;

    public constructor(key: any, name: string, active: boolean, tooltipContent?: string) {
        this.key = key;
        this.name = name;
        this.active = !!active;
        this.tooltipContent = tooltipContent;
    }
}
