<div *ngIf="!!(showHeader$ | async)"
     class="round-quick-filters-title">
    Kiirfiltrid:
</div>

<div #contentContainer
     class="round-quick-filters-content"
     [style.max-width]="maxWidth$ | async"
     [class.has-overflow]="!!(hasOverflow$ | async) && !!(showHeader$ | async)"
     [class.open]="!!(isOpen$ | async)">
    <ng-content></ng-content>
</div>

<button *ngIf="!!(hasOverflow$ | async)"
        class="btn round-quick-filters-overflow-indicator"
        aria-hidden="true"
        [class.active]="!!(isOpen$ | async)"
        (click)="toggleOpen()">
    …
</button>
