<div class="modal-container-white">
  <div class="modal-header">
    <h4 id="dialog-sizes-name1" class="modal-title pull-left">{{message?.id ? 'Teavituse muutmine' : 'Teavituse lisamine'}}</h4>
    <button type="button" class="close pull-right" (click)="cancel()" aria-label="Sulge">
      <span class="submenu-hover"></span>
      <span class="icon icon_close"></span>
    </button>
  </div>

  <div class="modal-body work-details-full-width">
    <div class="work-details-1-row" [formGroup]="form">

      <div class="work-details-1-col mb-3">
        <label for="title" class="work-details-1-label">Pealkiri</label>
        <div class="w-100">
          <input type="text"
                 class="form-control"
                 id="title"
                 formControlName="title"
                 placeholder="Sisesta pealkiri"
                 [class.is-invalid]="hasErrors(form.get('title'))"
          />
        </div>
      </div>

      <div class="work-details-1-col mb-3">
        <label for="text" class="work-details-1-label">Sisu</label>
        <div class="w-100">
          <textarea class="form-control w-100"
                    formControlName="text"
                    id="text"
                    rows="3"
                    [class.is-invalid]="hasErrors(form.get('text'))"
          >
          </textarea>
        </div>
      </div>

      <div class="work-details-1-col mb-3">
        <label for="type" class="work-details-1-label">Tüüp</label>
        <div class="w-100">
          <ng-select class="custom w-100"
                     formControlName="type"
                     id="type"
                     [items]="messageTypes"
                     bindLabel="name"
                     bindValue="code"
                     [class.is-invalid]="hasErrors(form.get('type'))"
          ></ng-select>
        </div>
      </div>

      <div class="work-details-1-col mb-3">
        <label for="roleIds" class="work-details-1-label">Rollid</label>
        <div class="w-100">
          <ng-select class="custom w-100 mobile-select"
                     formControlName="roleIds"
                     id="roleIds"
                     [items]="roleService.roles$ | async"
                     bindLabel="name"
                     bindValue="id"
                     [multiple]="true"
                     [clearable]="true"
                     placeholder="Vali rollid"
          >
          </ng-select>
        </div>
      </div>

      <div class="work-details-1-col">
        <label for="isPublished" class="work-details-1-label"></label>
        <div class="w-100">
          <div class="btn-group"
               btnRadioGroup
               formControlName="isPublished"
               id="isPublished"
               [class.is-invalid]="hasErrors(form.get('isPublished'))"
          >
            <label uncheckable
                   class="btn btn-outline-primary btn-sm"
                   btnRadio="true"
                   role="button"
            >
              Salvesta ja saada kasutajatele
            </label>
            <label uncheckable
                   class="btn btn-outline-primary btn-sm"
                   btnRadio="false"
                   role="button"
                   [class.is-invalid]="hasErrors(form.get('isPublished'))"
            >
              Salvesta mustandina
            </label>
          </div>
        </div>
      </div>

    </div>
  </div>

  <div class="modal-footer">
    <button (click)="cancel()" type="button" class="btn btn-outline-primary mr-4">Katkesta</button>
    <button [disabled]="loading" (click)="confirm(message?.id)" type="button" class="btn btn-outline-primary">Salvesta</button>
  </div>
</div>
