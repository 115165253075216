<ng-template #popoverTemplate>
    <div id="popover-table" class="d-none"></div>
    <div class="comment-container">
        <div *ngFor="let comment of comments">
            <span class="font-weight-bold">{{ comment?.userName }}:</span> {{ comment?.text }}
        </div>
    </div>
</ng-template>

<span
    container="body"
    class="btn-link cost-popover"
    [outsideClick]="true"
    placement="bottom left"
    [adaptivePosition]="false"
    [popover]="popoverTemplate"
    #popover>
    <span class="messages-label"
          [class.active]="row.commentCount > 0"
          [class.messages-unread]="row.hasNotifications">
        {{ row.commentCount ? row.commentCount : '-' }}
    </span>
</span>
