import {Injectable} from '@angular/core';
import {IResourceMethodStrict, ResourceAction, ResourceHandler, ResourceRequestMethod} from '@ngx-resource/core';
import {ConfigService} from '@app/config/config.service';
import {AuthGuardedResource} from './auth-guarded.resource';
import {AuthService} from '@app/core/services/auth.service';
import {OrderDto} from '../resource-dto/order/order';

@Injectable({providedIn: 'root'})
export class InspectedServiceRes extends AuthGuardedResource {

    public constructor(
        protected readonly requestHandler: ResourceHandler,
        protected readonly authService: AuthService,
        protected readonly config: ConfigService
    ) {
        super(requestHandler, authService, config);

        this.$setUrl(config.getBackendUrl('/inspections'));
    }

    @ResourceAction({
        method: ResourceRequestMethod.Put,
        path: '/{!inspectionId}/inspected-services'
    })
    update: IResourceMethodStrict<OrderDto.OrderInput, any, { inspectionId: number }, OrderDto.Order>;
}
