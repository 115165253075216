import {Component, ContentChildren, ElementRef, HostListener, OnDestroy, QueryList, ViewChild} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {distinctUntilChanged, filter, takeUntil} from 'rxjs/operators';
import {RoundQuickFilterEntryComponent} from './entry';

@Component({
    selector: 'app-round-quick-filters',
    templateUrl: './round-quick-filters.component.html'
})
export class RoundQuickFiltersComponent implements OnDestroy {

    private readonly ngDestroy = new Subject<void>();

    private readonly showHeaderSubject = new BehaviorSubject(false);
    public readonly showHeader$ = this.showHeaderSubject.asObservable()
        .pipe(takeUntil(this.ngDestroy), distinctUntilChanged());

    private readonly hasOverflowSubject = new BehaviorSubject(false);
    public readonly hasOverflow$ = this.hasOverflowSubject.asObservable()
        .pipe(takeUntil(this.ngDestroy), distinctUntilChanged());

    private readonly maxWidthSubject = new BehaviorSubject<number | undefined>(undefined);
    public readonly maxWidth$ = this.maxWidthSubject.asObservable()
        .pipe(takeUntil(this.ngDestroy), distinctUntilChanged(), filter(x => x !== undefined));

    private readonly isOpenSubject = new BehaviorSubject(false);
    public readonly isOpen$ = this.isOpenSubject.asObservable()
        .pipe(takeUntil(this.ngDestroy), distinctUntilChanged());

    private container: HTMLDivElement;

    @ContentChildren(RoundQuickFilterEntryComponent)
    public set content(input: QueryList<RoundQuickFilterEntryComponent>) {
        this.showHeaderSubject.next(!!input?.length);
        this.updateOverflow();
    }

    @ViewChild('contentContainer')
    public set contentContainer(elementRef: ElementRef) {
        this.container = elementRef.nativeElement as HTMLDivElement;
        this.updateOverflow();
    }

    @HostListener('window:resize', ['$event'])
    public onResize(_: Event): void {
        this.updateOverflow();
    }

    public ngOnDestroy(): void {
        this.ngDestroy.next();
        this.ngDestroy.complete();
    }

    public toggleOpen(): void {
        this.isOpenSubject.next(!this.isOpenSubject.value);

        this.updateOverflow();
    }

    private updateOverflow(): void {
        setTimeout(() => {
            if (!this.container) return;
            if (!this.showHeaderSubject.value) return;

            const entry = this.container.firstElementChild;
            const maxHeight = entry.clientHeight * 2;
            const innerHeight = this.container.scrollHeight;
            const hasOverflow = maxHeight < innerHeight;

            this.hasOverflowSubject.next(hasOverflow);
            if (!!hasOverflow) return;

            this.isOpenSubject.next(false);
        }, 0);
    }
}
