import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {LegendEntry} from './model';

@Component({
    templateUrl: './legend.component.html',
    selector: 'app-legend'
})
export class LegendComponent implements OnInit, OnChanges, OnDestroy {

    @Input()
    public entries: LegendEntry[];

    private readonly ngDestroy = new Subject<void>();
    private readonly entriesSubject = new BehaviorSubject<LegendEntry[]>([]);
    public readonly entries$ = this.entriesSubject.asObservable().pipe(takeUntil(this.ngDestroy), distinctUntilChanged());

    public ngOnInit(): void {
        this.entriesSubject.next(this.entries || []);
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.entries) {
            this.entriesSubject.next(this.entries || []);
        }
    }

    public ngOnDestroy(): void {
        this.ngDestroy.next();
        this.ngDestroy.complete();
    }
}
