<div dropdown
     class="submenu ml-3 h-100"
     [insideClick]="true"
     [formGroup]="form">
  <span class="submenu-hover"></span>
  <button dropdownToggle
          id="column-button"
          class="btn btn-link d-flex"
          aria-label="Tabeli seaded">
    <span class="icon icon_settings d-flex"
          aria-hidden="true">
    </span>
  </button>
  <ul *dropdownMenu
      class="dropdown-menu dropdown-menu-right dropdown-menu-limit-height"
      role="menu"
      aria-labelledby="column-button">
    <li *ngFor="let item of columns | keyvalue : columnOrder" class="p-0">
      <div *ngIf="item.value.enabled !== undefined " class="custom-control custom-checkbox dropdown-item" (click)="toggle(item.key)">
        <input class="custom-control-input" type="checkbox" [formControlName]="item.key">
        <label class="custom-control-label">
          {{item.value.name}}
        </label>
      </div>
    </li>
  </ul>
</div>
