import {Injectable} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import * as moment from 'moment';
import {SessionTimeoutModalComponent} from '../components/session-timeout-modal/session-timeout-modal.component';
import {AuthService} from './auth.service';

@Injectable({providedIn: 'root'})
export class SessionTimeoutService {

    private readonly showTimeoutModalDiff = 180000;
    private sessionExpireTime: Date;
    private sessionTimeoutModalRef: BsModalRef;
    private showTimeoutModalTimeout: number;

    public constructor(
        private readonly authService: AuthService,
        private readonly modalService: BsModalService
    ) {}

    public init(): void {
        this.authService.sessionExpireTime$.subscribe(date => {
            if (this.showTimeoutModalTimeout) {
                window.clearTimeout(this.showTimeoutModalTimeout);
            }
            this.sessionExpireTime = date;
            const timeoutMs = moment(date).diff(new Date(), 'ms');
            const showTimeoutMs: number = timeoutMs < this.showTimeoutModalDiff ? 1 : timeoutMs - this.showTimeoutModalDiff;

            this.showTimeoutModalTimeout = window.setTimeout(() => this.showSessionTimeoutModal(), showTimeoutMs);
        });
    }

    private showSessionTimeoutModal() {
        if (!!this.sessionTimeoutModalRef) return;

        this.sessionTimeoutModalRef = this.modalService.show(
            SessionTimeoutModalComponent,
            {
                backdrop: 'static',
                keyboard: false,
                initialState: {
                    sessionExpireTime: this.sessionExpireTime
                }
            }
        );

        this.sessionTimeoutModalRef.content.close.subscribe({next: (_: any) => this.sessionTimeoutModalRef = null});
    }
}
