import {Component, Input} from '@angular/core';
import {LegendEntry} from '../model';

@Component({
    templateUrl: './legend-entry.component.html',
    selector: 'app-legend-entry'
})
export class LegendEntryComponent {

    @Input()
    public entry!: LegendEntry;

    public get color(): string {
        return this.entry?.color;
    }

    public get description(): string {
        return this.entry?.description;
    }
}
