import {Component, OnInit, Input, forwardRef, ViewChild, Injector, OnDestroy} from '@angular/core';
import {NgbDatepicker} from '@ng-bootstrap/ng-bootstrap';
import {NG_VALUE_ACCESSOR, ControlValueAccessor, NgControl} from '@angular/forms';
import {DatePipe} from '@angular/common';
import {BehaviorSubject, noop, Subject} from 'rxjs';
import * as moment from 'moment';
import {Moment} from 'moment';
import {distinctUntilChanged, map, startWith, takeUntil} from 'rxjs/operators';
import {DateTimePickerHelper} from './date-time-picker-helper';

@Component({
    selector: 'app-date-time-picker',
    templateUrl: './date-time-picker.component.html',
    providers: [
        DatePipe,
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DateTimePickerComponent),
            multi: true
        }
    ]
})
export class DateTimePickerComponent implements ControlValueAccessor, OnInit, OnDestroy {

    @Input()
    public set minMoment(aMoment: Moment | undefined) {
        this.minMomentSubject.next(aMoment);
    }

    @Input()
    public set maxMoment(aMoment: Moment | undefined) {
        this.maxMomentSubject.next(aMoment);
    }

    @Input()
    public datetimeString: string;

    @Input()
    public onlyDatePicker = false;

    @Input()
    public inputDatetimeFormat = 'dd.MM.yyyy H:mm';

    @Input()
    public hourStep = 1;

    @Input()
    public minuteStep = 15;

    @Input()
    public secondStep = 30;

    @Input()
    public seconds = false;

    @Input()
    public disabled = false;

    @Input()
    public placeholder = '';

    @ViewChild(NgbDatepicker)
    private dp: NgbDatepicker;

    public time: Date;
    public showTimePickerToggle = false;
    public initialDatetime: Moment = null;
    public datetime: Moment = null;
    public ngControl: NgControl;

    private readonly ngDestroy = new Subject<void>();

    private readonly minMomentSubject = new BehaviorSubject<Moment | undefined>(undefined);
    private readonly minMomentObservable = this.minMomentSubject.asObservable()
        .pipe(takeUntil(this.ngDestroy), startWith(this.minMomentSubject.getValue()), distinctUntilChanged());
    public readonly minDate$ = this.minMomentObservable.pipe(takeUntil(this.ngDestroy), map(x => x?.toDate()));
    public readonly minNgbDateStruct$ = this.minDate$
        .pipe(takeUntil(this.ngDestroy), map(x => DateTimePickerHelper.dateToNgbDateStruct(x), distinctUntilChanged()));

    private readonly maxMomentSubject = new BehaviorSubject<Moment | undefined>(undefined);
    private readonly maxMomentObservable = this.maxMomentSubject.asObservable()
        .pipe(takeUntil(this.ngDestroy), startWith(this.maxMomentSubject.getValue()), distinctUntilChanged());
    public readonly maxDate$ = this.maxMomentObservable.pipe(takeUntil(this.ngDestroy), map(x => x?.toDate()));
    public readonly maxNgbDateStruct$ = this.maxDate$
        .pipe(takeUntil(this.ngDestroy), map(x => DateTimePickerHelper.dateToNgbDateStruct(x), distinctUntilChanged()));

    private onTouched: () => void = noop;
    private onChange: (_: any) => void = noop;

    public constructor(private readonly inj: Injector) {
        this.time = new Date();
        this.time.setSeconds(0, 0);
    }

    public ngOnInit(): void {
        this.ngControl = this.inj.get(NgControl);
    }

    public ngOnDestroy(): void {
        this.ngDestroy.next();
        this.ngDestroy.complete();
    }

    public onPopoverHidden(): void {
        this.showTimePickerToggle = false;
        if (!!this.initialDatetime && this.initialDatetime?.isSame(this.datetime)) return;

        this.onChange(this.datetime);
        this.updateDateString();
    }

    public writeValue(value: Moment): void {
        if (typeof value === 'string' || value instanceof String) return;

        this.initialDatetime = value?.clone() || null;
        this.datetime = value || null;
        this.updateDateString();
    }

    public registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    public toggleDateTimeState($event): void {
        this.showTimePickerToggle = !this.showTimePickerToggle;
        $event.stopPropagation();
    }

    public setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    public onInputChange($event: any) {
        const value = $event.target.value;
        const dt = moment(value, 'DD.MM.YYYY HH:mm');

        if (dt.isValid()) {
            this.datetime = dt;
            this.time = dt.toDate();

            if (this.dp) {
                this.dp.navigateTo({year: this.datetime.year(), month: this.datetime.month() + 1});
            }
        } else if (value.trim() === '') {
            this.datetime = null;
        }

        this.onChange(this.datetime);
    }

    public onDateChange($event: Moment) {
        if ($event) {
            this.datetime = moment($event.format('YYYY-MM-DD'), 'YYYY-MM-DD');
            this.datetime.hour(this.time.getHours());
            this.datetime.minute(this.time.getMinutes());
            this.datetime.second(this.time.getSeconds());
        } else {
            this.datetime = null;
        }

        if (this.datetime && this.datetime.isValid()) {
            this.dp.navigateTo({year: this.datetime.year(), month: this.datetime.month() + 1, day: this.datetime.day()});
            this.time = this.datetime.toDate();
        }

        this.updateDateString();
    }

    public onTimeChange(event: Date) {
        this.time = event;
        this.datetime.hour(event.getHours());
        this.datetime.minute(event.getMinutes());
        this.datetime.second(event.getSeconds());
        this.updateDateString();
    }

    public updateDateString() {
        if (this.datetime && moment.isMoment(this.datetime)) {
            this.datetimeString = this.datetime.format();
        } else {
            this.datetimeString = '';
        }
    }

    public inputBlur(_: Event): void {
        this.onTouched();
    }
}
