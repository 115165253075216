import {Component, HostListener, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {takeUntil} from 'rxjs/operators';
import {BehaviorSubject, Subject} from 'rxjs';
import {PopoverDirective} from 'ngx-bootstrap/popover';
import {SelectFilterOption} from './quick-filter-select.option';

@Component({
    // tslint:disable-next-line:component-selector
    selector: '[quickFilterSelect]',
    templateUrl: './quick-filter-select.component.html'
})
export class QuickFilterSelectComponent implements OnChanges, OnDestroy {

    @Input()
    public tooltipContent?: string;

    @Input()
    public disabled = false;

    @Input()
    public innerFormControl!: FormControl;

    @Input()
    public options!: SelectFilterOption[];

    @ViewChild('popover')
    public popover: PopoverDirective;

    private readonly onDestroy = new Subject<void>();
    private readonly onChanges = new Subject<void>();
    private readonly currentOptionSubject = new BehaviorSubject<SelectFilterOption>(undefined);
    public readonly currentOption$ = this.currentOptionSubject.asObservable();

    @HostListener('window:scroll', ['$event'])
    public onScroll(__): void {
        if (!this.popover) return;
        if (!this.popover.isOpen) return;

        this.popover.hide();
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (!!changes.innerFormControl) {
            this.onChanges.next();
            this.listenToFormControl();
        }
        if (!!changes.options) {
            this.updateSelection();
        }
    }

    public ngOnDestroy() {
        this.onChanges.next();
        this.onChanges.complete();
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    public select(option: SelectFilterOption): void {
        this.innerFormControl.setValue(option?.value);
        if (!this.popover) return;

        this.popover.hide();
    }

    private listenToFormControl(): void {
        if (!this.innerFormControl) return;

        this.innerFormControl.valueChanges
            .pipe(takeUntil(this.onChanges), takeUntil(this.onDestroy))
            .subscribe({next: _ => this.updateSelection()});
    }

    private updateSelection(): void {
        if (!this.innerFormControl || !this.options) {
            this.currentOptionSubject.next(undefined);

            return;
        }

        const currentValue = this.innerFormControl.value;
        const filtered = this.options.filter(x => x.value === currentValue);
        if (filtered.length === 0) {
            this.currentOptionSubject.next(undefined);
        } else {
            this.currentOptionSubject.next(filtered[0]);
        }
    }
}
