import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'app-round-quick-filter-entry',
    templateUrl: './round-quick-filter-entry.component.html'
})
export class RoundQuickFilterEntryComponent {

    @Input()
    public key!: any;

    @Input()
    public name!: string;

    @Input()
    public active!: boolean;

    @Input()
    public tooltipContent?: string;

    @Input()
    public container = 'body';

    @Input()
    public disabled!: boolean;

    @Output()
    public readonly clicked = new EventEmitter<any>();

    public emitClick(): void {
        this.clicked.emit(this.key);
    }
}
