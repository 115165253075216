<div class="container-fluid pl-0 pr-0" [formGroup]="orderForm">

    <div class="navbar navbar-general-page">
        <app-create-quest-actions></app-create-quest-actions>
    </div>

    <div class="white-container mt-3 justify-content-center">
        <div class="alert alert-danger" role="alert" *ngIf="showFormErrors">
            <button type="button" class="close" aria-label="Close" (click)="dismissErrors()">
                <span aria-hidden="true">&times;</span>
            </button>
            {{ 'Vormil esineb vigu!' | translate }}
        </div>
        <div class="alert alert-success text-center" role="alert"
             *ngIf="!showFormErrors && formSent">
            <h3>{{ 'Taotlus esitatud!' | translate }}</h3><br>{{ 'Uue probleemi teavitamiseks' | translate }} <a
            href="javascript:void(0)" (click)="reset()">{{ 'vajuta siia' | translate }}</a>!
        </div>

        <div *ngIf="!formSent">
            <div class="block block-white mr-auto ml-auto">
                <h5 class="block-title title-border-bottom">{{ 'Detailid' | translate }}</h5>
                <div class="p-4 p-md-4 p-lg-5">
                    <div>
                        <section class="box-typical" public-building-section
                                 [formErrors]="formErrors"></section>
                        <div *ngIf="!(isMinistryPage$ | async)">
                            <hr/>
                            <section class="box-typical" form-requester-quest-section
                                     [formErrors]="formErrors"></section>
                        </div>
                    </div>
                </div>
            </div>

            <div class="block block-white mt-4 mb-4 mr-auto ml-auto">
                <h5 class="block-title title-border-bottom">{{ 'Probleem' | translate }}</h5>

                <div class="p-4 p-md-4 p-lg-5">
                    <div class="form-row custom-form-row">
                        <div class="col-12">
                            <label>{{ 'Probleem' | translate }}</label>
                            <textarea formControlName="description"
                                      [class.is-invalid]="formErrors.description" class="form-control"
                                      id="building" rows="4"></textarea>
                        </div>
                        <small *ngIf="formErrors?.description?.required"
                               class="invalid-feedback d-block">{{ formErrors.description?.required }}</small>
                    </div>
                    <form-files-quest-section [orderFileType]="'ORFI_ORDER'"></form-files-quest-section>
                </div>
            </div>
        </div>
    </div>
</div>
