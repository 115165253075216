import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date-struct';

export class DateTimePickerHelper {

    public static dateToNgbDateStruct(date: Date): NgbDateStruct | undefined {
        if (!date) return undefined;
        if (isNaN(date.getTime())) return undefined;

        return {
            year: date.getFullYear(),
            month: date.getMonth() + 1,
            day: date.getDate()
        };
    }
}
