import {Component, Input} from '@angular/core';
import {MessageDto} from '@app/core/resource-dto/messages';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ClassifierService} from '@app/core/services/classifier.service';
import {RolesRequestService} from '@app/admin/roles/roles-request.service';
import {MessagesService} from '@app/messages/messages.service';
import MessageRequest = MessageDto.MessageRequest;
import {MessagesRowModel} from '@app/messages/list/messages/messages-row.model';

@Component({
  templateUrl: './messages-modal.component.html',
  providers: [RolesRequestService]
})
export class MessagesModalComponent {

  @Input()
  public set messageRow(response: MessagesRowModel) {
    this.message = response;

    this.setFormData(response);
  }

  public messageTypes = [];
  public roles = [];
  public loading = false;
  public message: MessagesRowModel;

  public readonly form: FormGroup = new FormGroup({
    title: new FormControl('', Validators.required),
    text: new FormControl('', Validators.required),
    type: new FormControl('', Validators.required),
    isPublished: new FormControl('', Validators.required),
    roleIds: new FormControl([])
  });

  constructor(
    private modalRef: BsModalRef,
    private classifierService: ClassifierService,
    public roleService: RolesRequestService,
    public messageService: MessagesService
  ) {
    this.listenToClassifierSource();
    this.roleService.loadRoles();
  }

  public listenToClassifierSource(): void {
    this.classifierService.classifierSource$.subscribe(_ => {
      const classifiers = this.classifierService.getClassifierByCode('MESSAGE_TYPE');

      classifiers.values.forEach(value => {
        this.messageTypes.push({
          code: value.code,
          name: value.name
        });
      });
    });
  }

  public setFormData(message: MessagesRowModel) {
    if (!!message) {
      this.form.patchValue({
        title: !!message.title ? message.title : null,
        text: !!message.text ? message.text : null,
        type: !!message.type ? message.type : null,
        isPublished: message.isPublished ? 'true' : 'false',
        roleIds: !!message.roles ? message.roles.map(r => r.id) : null
      });
    }
  }

  public cancel(): void {
    this.modalRef.hide();
    this.loading = false;
  }

  public confirm(messageId?: number): void {
    this.form.updateValueAndValidity();
    this.form.markAllAsTouched();

    if (this.form.invalid) return;

    this.loading = true;

    const formModel = this.form.value;

    const request = new MessageRequest();

    request.title = formModel.title;
    request.text = formModel.text;
    request.type = formModel.type;
    request.isPublished = formModel.isPublished;
    request.roleIds = formModel.roleIds;

    if (messageId) {
      this.messageService.updateMessage(request, messageId);
    } else {
      this.messageService.addMessage(request);
    }

    this.modalRef.hide();
  }

  public hasErrors(field) {
    return field.invalid && (field.dirty || field.touched);
  }
}
