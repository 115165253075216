import {PipeTransform, Pipe} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({name: 'sanitize'})
export class SanitizePipe implements PipeTransform {

    constructor(private _sanitizer: DomSanitizer) {}

    public transform(value: any): any {
        return this._sanitizer.bypassSecurityTrustHtml(value);
    }
}
