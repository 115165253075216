import {BehaviorSubject, ReplaySubject} from 'rxjs';

export class Breadcrumb {

    private readonly nameSource = new ReplaySubject<string>();
    public readonly name$ = this.nameSource.asObservable();

    private readonly hasMobileSource = new BehaviorSubject<boolean>(false);

    public constructor(name: string, hasMobile?: boolean) {
        this.nameSource.next(name);
        this.hasMobileSource.next(!!hasMobile);
    }

    public set name(name: string) {
        this.nameSource.next(name);
    }

    public get hasMobile(): boolean {
        return !!this.hasMobileSource?.getValue();
    }
}
