import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import {QuickFilterButtonOptions} from './quick-filter-button.options';

@Component({
    // tslint:disable-next-line:component-selector
    selector: '[quickFilterButton]',
    templateUrl: './quick-filter-button.component.html'
})
export class QuickFilterButtonComponent {

    @Input()
    public tooltipContent: string;

    @Input()
    public count?: number;

    @Input()
    public disabled = false;

    @Input()
    public type: 'primary' | 'readonly' = 'primary';

    @Input()
    public options?: QuickFilterButtonOptions;

    @Input()
    public innerFormControl: FormControl;

    @Input()
    public state?: boolean;

    @Output()
    public readonly select = new EventEmitter<void>();

    public get hasCount(): boolean {
        return ![null, undefined].includes(this.count);
    }

    public onClick(): void {
        if (this.disabled || this.type === 'readonly') return;

        this.select.emit();

        if (!this.innerFormControl) return;

        const update = !this.innerFormControl.value;
        if (this.options) {
            this.innerFormControl.patchValue(this.options[String(update)]);
        } else {
            this.innerFormControl.patchValue(update);
        }
    }
}
