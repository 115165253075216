import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'btn-filter-menu',
  templateUrl: './filter-menu-button.component.html'
})
export class FilterMenuButtonComponent {

  @Input()
  public active = false;

  @Input()
  public disabled = false;

  @Input()
  public innerClass?: string;

  @Output()
  public toggleFilters = new EventEmitter();

}
