import {Component, Input} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';

@Component({
  templateUrl: './messages-user-modal.component.html'
})
export class MessagesUserModalComponent {

  @Input()
  public row;

  constructor(private modalRef: BsModalRef) {
  }

  close() {
    this.modalRef.hide();
  }
}
