<div class="input-group mr-2">

    <input class="form-control"
           [ngClass]="ngControl?.valid ? 'ng-valid' : 'ng-invalid'"
           [placeholder]="placeholder"
           [ngModel]="datetimeString | date:inputDatetimeFormat"
           [disabled]='disabled'
           (blur)="inputBlur($event)"
           (change)="onInputChange($event)"/>

    <div class="input-group-append">
        <button class="btn btn-outline-secondary"
                containerClass="date-time-picker-container"
                type="button"
                outsideClick="true"
                aria-description="Vali kuupäev"
                [disabled]='disabled'
                [popover]="calendarContent"
                (onHidden)="onPopoverHidden()">
            <i class="far fa-calendar" aria-hidden="true"></i>
        </button>
    </div>

</div>

<ng-template #calendarContent>
    <div>

        <div *ngIf="!showTimePickerToggle">

            <ngb-datepicker #dp
                            name="datepicker"
                            [minDate]="minNgbDateStruct$ | async"
                            [maxDate]="maxNgbDateStruct$ | async"
                            [ngModel]="datetime"
                            (ngModelChange)="onDateChange($event)">
            </ngb-datepicker>

            <button *ngIf="!onlyDatePicker"
                    class="btn btn-block btn-outline-secondary"
                    type="button"
                    aria-description="Vali kellaaeg"
                    [disabled]="!datetime?.day"
                    (click)="toggleDateTimeState($event)">
                <i class="far fa-clock" aria-hidden="true"></i>
            </button>

        </div>

        <div *ngIf="showTimePickerToggle && !onlyDatePicker">

            <button class="btn btn-block btn-outline-secondary"
                    type="button"
                    aria-description="Vali kuupäev"
                    [popover]="calendarContent"
                    (click)="toggleDateTimeState($event)">
                <i class="far fa-calendar" aria-hidden="true"></i>
            </button>

            <div class="mt-auto d-flex justify-content-center">
                <timepicker #tp
                            class="date-time-picker__timepicker"
                            name="timepicker"
                            [min]="minDate$ | async"
                            [max]="maxDate$ | async"
                            [showMeridian]="false"
                            [ngModel]="time"
                            [showSeconds]="seconds"
                            [hourStep]="hourStep"
                            [minuteStep]="minuteStep"
                            [secondsStep]="secondStep"
                            (ngModelChange)="onTimeChange($event)">
                </timepicker>
            </div>

        </div>

    </div>
</ng-template>

