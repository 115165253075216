import {HttpParams} from '@angular/common/http';
import {ObjectUtils} from '@app/shared/utils';

export class PagingUtils {

    static getParams(request: any): HttpParams {
        if (!request) {
            return new HttpParams();
        }
        return ObjectUtils.toObject(Object.entries(request).map(([key, value]) => [key, value]));
    }
}
