import {PipeTransform, Pipe, OnDestroy} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';
import {ApplianceTypeService} from '@app/core/services/appliance-type.service';

@Pipe({name: 'applianceTypeObservable'})
export class ApplianceTypePipe implements PipeTransform, OnDestroy {

    private readonly ngDestroy = new Subject<void>();

    public constructor(private applianceTypeService: ApplianceTypeService) {}

    public ngOnDestroy(): void {
        this.ngDestroy.next();
        this.ngDestroy.complete();
    }

    public transform(value: any): Observable<string | undefined> {
        return this.applianceTypeService.getApplianceNameObservable(value)
            .pipe(map(x => x?.trim() || value));
    }
}
