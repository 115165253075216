<div class="works-link"
     [class.disabled]="disabled"
     [ngClass]="type"
     [btnRadio]="value"
     [uncheckable]="type === 'readonly'"
     [disabled]="type === 'readonly'"
     [tooltip]="tooltipContent">
    <span class="works-link-label text-sm text-nowrap d-flex align-items-center">
        <ng-content></ng-content>
    </span>
    <h4 *ngIf="hasCount" class="works-filtered-number">
        {{count}}
    </h4>
</div>
