import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgSelectModule} from '@ng-select/ng-select';
import {NgbDatepickerModule, NgbPopoverModule} from '@ng-bootstrap/ng-bootstrap';
import {TranslateModule} from '@ngx-translate/core';
import {NgxPermissionsModule, NgxPermissionsRestrictStubModule} from 'ngx-permissions';
import {PopoverModule} from 'ngx-bootstrap/popover';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {TimepickerModule} from 'ngx-bootstrap/timepicker';
import {ModalModule} from 'ngx-bootstrap/modal';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {SanitizePipe} from '@app/shared/pipes/sanitize.pipe';
import {FooterComponent} from './components/footer/footer.component';
import {HeaderComponent} from './components/header/header.component';
import {UserLinkDirective} from './components/user-link.directive';
import {UserLinkComponent} from './components/user-link/user-link.component';
import {SidenavComponent} from './components/sidenav/sidenav.component';
import {SideMenuComponent} from './components/side-menu/side-menu.component';
import {UserDetailModalComponent} from './components/user-detail-modal/user-detail-modal.component';
import {SyncModalComponent} from './components/admin/sync-modal/sync-modal.component';
import {AppNotificationModalComponent} from './components/admin/app-notification-modal/app-notification-modal.component';
import {DateTimePickerComponent} from './components/date-time-picker/date-time-picker.component';
import {BadgeComponent} from './components/badge/badge.component';
import {ClassifierClassDirective} from './components/classifier-class.directive';
import {NotificationModalComponent} from './components/notification-modal/notification-modal.component';
import {ArchiveModalComponent} from './components/admin/archive-modal/archive-modal.component';
import {DetailBlockTabComponent} from '@app/core/components/detail-block-tab/detail-block-tab.component';
import {FilterMenuButtonComponent} from '@app/core/components/filters/filter-menu-button/filter-menu-button.component';
import {DaterangePickerComponent} from '@app/core/components/daterange-picker/daterange-picker.component';
import {SessionTimeoutModalComponent} from '@app/core/components/session-timeout-modal/session-timeout-modal.component';
import {UserViewSettingComponent} from './components/user-view-setting/user-view-setting.component';
import {HeadmenuComponent} from './components/header/headmenu.component';
import {NavbarComponent} from '@app/core/components/navbar/navbar.component';
import {NavbarMenuComponent} from '@app/core/components/navbar/menu/navbar-menu.component';
import {NavbarBreadcrumbsComponent} from '@app/core/components/navbar/breadcrumbs/navbar-breadcrumbs.component';
import {NavbarBadgeComponent} from '@app/core/components/navbar/navbar-badge/navbar-badge.component';
import {RepresentativeModalComponent} from '@app/core/components/representative-modal/representative-modal.component';
import {UserNotificationPermissionsModalComponent} from '@app/core/components/user-notification-permissions-modal/user-notification-permissions-modal.component';
import {RepresentativeSelectComponent} from '@app/core/components/representative-modal/representative-select.component';
import {KeysPipe} from '@app/core/pipes/keys.pipe';
import {ClassifierLabelPipe} from '@app/core/pipes/classifier.pipe';
import {ApplianceTypePipe} from '@app/core/pipes/appliance-type.pipe';
import {StrToNumberPipe} from '@app/core/pipes/str-to-number.pipe';
import {CacheClearComponent, UserregistrySyncComponent, PropertySyncComponent, ContractSyncComponent, ApplianceHierarchyMvRefreshComponent} from '@app/core/components/admin/elements';
import {ConfirmModalComponent} from '@app/core/components/confirm-modal/confirm-modal.component';
import {FrequencyBadgeComponent} from '@app/core/components/badge/frequency-badge.component';
import {PopoverComponent} from './components/popover/popover.component';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {DueDateBadgeComponent} from '@app/core/components/badge/due-date-badge.component';
import {ProjectManagerNotificationModalComponent} from './components/admin/project-manager-notification-modal/project-manager-notification-modal.component';
import {MessagesModalComponent} from './components/messages-modal/messages-modal.component';
import {MessagesUserModalComponent} from './components/messages-user-modal/messages-user-modal.component';
import {ButtonsModule} from 'ngx-bootstrap/buttons';
import {GenericBadgeComponent} from '@app/core/components/badge/generic-badge.component';
import {AsideComponent} from '@app/core/components/aside/aside.component';
import {QuickFilterRadioComponent} from '@app/core/components/filters/quick-filter-radio/quick-filter-radio.component';
import {QuickFilterSelectComponent} from '@app/core/components/filters/quick-filter-select/quick-filter-select.component';
import {QuickFilterButtonComponent} from '@app/core/components/filters/quick-filter-button/quick-filter-button.component';
import {ClassifierLabelObservablePipe} from '@app/core/pipes/classifier-observable.pipe';
import {LegendComponent, LegendEntryComponent} from './components/legend';
import {RoundQuickFilterEntryComponent, RoundQuickFiltersComponent} from './components/filters/round-quick-filters';
import {QuickFilterMultiselectComponent} from '@app/core/components/filters/quick-filter-multiselect/quick-filter-multiselect.component';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {AuthInterceptor} from '@app/core/interceptor/auth-interceptor';
import {MaintenanceScheduleMvRefreshComponent} from '@app/core/components/admin/elements/maintenance-schedule-mv-refresh';
import {MaintenanceOrdersSetApprovedComponent} from './components/admin/elements/maintenance-orders-set-approved/maintenance-orders-set-approved.component';
import {OrderCommentPopoverComponent} from './components/order-comment-popover/order-comment-popover.component';
import {IconButtonComponent} from './components/icon-button/icon-button.component';
import {LetDirective, SwipeableDirective, SwipeableAnimationAreaDirective} from './directive';
import {MobileBreadcrumbsComponent} from '@app/core/components/mobile';

@NgModule({
    declarations: [
        FooterComponent,
        HeaderComponent,
        NavbarComponent,
        NavbarMenuComponent,
        NavbarBreadcrumbsComponent,
        NavbarBadgeComponent,
        SidenavComponent,
        AsideComponent,
        DetailBlockTabComponent,
        RepresentativeModalComponent,
        UserNotificationPermissionsModalComponent,
        RepresentativeSelectComponent,
        SideMenuComponent,
        KeysPipe,
        ClassifierLabelPipe,
        ClassifierLabelObservablePipe,
        ApplianceTypePipe,
        StrToNumberPipe,
        SanitizePipe,
        UserDetailModalComponent,
        UserLinkDirective,
        UserLinkComponent,
        SyncModalComponent,
        ArchiveModalComponent,
        AppNotificationModalComponent,
        ProjectManagerNotificationModalComponent,
        ContractSyncComponent,
        PropertySyncComponent,
        UserregistrySyncComponent,
        CacheClearComponent,
        ApplianceHierarchyMvRefreshComponent,
        MaintenanceScheduleMvRefreshComponent,
        DateTimePickerComponent,
        BadgeComponent,
        GenericBadgeComponent,
        FrequencyBadgeComponent,
        ClassifierClassDirective,
        ConfirmModalComponent,
        NotificationModalComponent,
        FilterMenuButtonComponent,
        DaterangePickerComponent,
        SessionTimeoutModalComponent,
        UserViewSettingComponent,
        HeadmenuComponent,
        PopoverComponent,
        DueDateBadgeComponent,
        MessagesModalComponent,
        MessagesUserModalComponent,
        QuickFilterRadioComponent,
        QuickFilterSelectComponent,
        QuickFilterMultiselectComponent,
        QuickFilterButtonComponent,
        LegendComponent,
        LegendEntryComponent,
        RoundQuickFiltersComponent,
        RoundQuickFilterEntryComponent,
        MaintenanceOrdersSetApprovedComponent,
        OrderCommentPopoverComponent,
        IconButtonComponent,
        LetDirective,
        SwipeableDirective,
        SwipeableAnimationAreaDirective,
        MobileBreadcrumbsComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        NgxPermissionsModule,
        PopoverModule,
        NgSelectModule,
        BsDropdownModule,
        TimepickerModule,
        ModalModule,
        BsDatepickerModule,
        NgbDatepickerModule,
        TranslateModule,
        TooltipModule,
        NgxPermissionsRestrictStubModule,
        ButtonsModule,
        NgbPopoverModule
    ],
    exports: [
        FooterComponent,
        HeaderComponent,
        NavbarComponent,
        SidenavComponent,
        SideMenuComponent,
        AsideComponent,
        DetailBlockTabComponent,
        RepresentativeModalComponent,
        UserDetailModalComponent,
        UserLinkComponent,
        ClassifierLabelPipe,
        ClassifierLabelObservablePipe,
        ApplianceTypePipe,
        StrToNumberPipe,
        KeysPipe,
        SanitizePipe,
        DateTimePickerComponent,
        BadgeComponent,
        GenericBadgeComponent,
        FrequencyBadgeComponent,
        ClassifierClassDirective,
        ConfirmModalComponent,
        NotificationModalComponent,
        FilterMenuButtonComponent,
        DaterangePickerComponent,
        UserViewSettingComponent,
        PopoverComponent,
        DueDateBadgeComponent,
        QuickFilterRadioComponent,
        QuickFilterSelectComponent,
        QuickFilterMultiselectComponent,
        QuickFilterButtonComponent,
        LegendComponent,
        LegendEntryComponent,
        RoundQuickFiltersComponent,
        IconButtonComponent,
        RoundQuickFilterEntryComponent,
        OrderCommentPopoverComponent,
        LetDirective,
        SwipeableDirective,
        SwipeableAnimationAreaDirective,
        MobileBreadcrumbsComponent
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        }
    ]
})
export class CoreModule {
}
