import {Component, Input, OnDestroy} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Subject, Observable} from 'rxjs';
import {distinctUntilChanged, map, share, switchMap, takeUntil} from 'rxjs/operators';
import {BuildingAdapter} from './building-adapter';
import {FormService} from '../form.service';
import {ManageService} from '../../manage.service';
import Manager = BuildingDto.Manager;
import Building = BuildingDto.Building;
import {BuildingDto} from '@app/core/resource-dto/building';
import {DeviceStateService} from '@app/core/services/device';


@Component({
    // tslint:disable-next-line:component-selector
    selector: '[form-building-section]',
    templateUrl: './building-section.component.html',
    providers: [BuildingAdapter]
})
export class FormBuildingSectionComponent implements OnDestroy {

    @Input()
    public formErrors: any;

    private readonly ngDestroy = new Subject<void>();
    private readonly building$: Observable<Building> = this.formService.orderForm.controls.building.valueChanges.pipe(
        distinctUntilChanged(),
        takeUntil(this.ngDestroy),
        switchMap(o => this.buildingAdapter.getBuilding(o?.id)),
        share()
    );
    private readonly propertyManager$: Observable<Manager> = this.building$.pipe(map(b => !!b?.manager ? b.manager : null));
    public readonly orderForm: FormGroup = this.formService.orderForm;
    public readonly options$ = this.buildingAdapter.options$;
    public readonly querySource = this.buildingAdapter.querySource;
    public readonly myBuildingsFilter = this.buildingAdapter.myBuildingsFilter;
    public readonly isMobileView$ = this.deviceStateService.isMobileView$;
    public propertyManager: Manager;

    public constructor(
        private readonly manageService: ManageService,
        private readonly formService: FormService,
        private readonly buildingAdapter: BuildingAdapter,
        private readonly deviceStateService: DeviceStateService
    ) {
        this.buildingAdapter.options$.subscribe();
        this.buildingAdapter.setBuildingAdapterCallerSource('order');
        this.listenToOrder();
        this.listenToPropertyManager();
    }

    public ngOnDestroy(): void {
        this.ngDestroy.next();
        this.ngDestroy.complete();
    }

    public changeTypeAhead(event: any): void {
        this.querySource.next(event?.term || '');
    }

    private listenToOrder(): void {
        this.manageService.order$.subscribe(order => {
            if (!order?.building?.id) {
                this.buildingAdapter.queryAllBuildings();

                return;
            }

            this.propertyManager = {
                id: order.building.managerId,
                name: order.building.managerName,
                email: order.building.managerEmail,
                phone: order.building.managerPhone
            };

            this.buildingAdapter.setOptions([{
                id: order.building.id,
                text: order.building.name
            }]);
        });
    }

    private listenToPropertyManager(): void {
        this.propertyManager$.subscribe(m => this.propertyManager = m);
    }
}
