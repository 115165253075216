import {Injectable} from '@angular/core';
import {IResourceMethodStrict, ResourceAction, ResourceHandler} from '@ngx-resource/core';
import {ConfigService} from '@app/config/config.service';
import {AuthService} from '../services/auth.service';
import {OrderEventDto} from '../resource-dto/order/order-event';
import {AuthGuardedResource} from './auth-guarded.resource';

@Injectable({providedIn: 'root'})
export class OrderEventRes extends AuthGuardedResource {

    public constructor(
        protected readonly requestHandler: ResourceHandler,
        protected readonly authService: AuthService,
        protected readonly config: ConfigService
    ) {
        super(requestHandler, authService, config);

        this.$setUrl(config.getBackendUrl('/orders/{!orderId}/events'));
    }

    @ResourceAction({
        expectJsonArray: false
    })
    query: IResourceMethodStrict<{orderId: number}, OrderEventDto.QueryInput, any, OrderEventDto.QueryOutput>;
}
