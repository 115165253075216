import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ViewType} from '@app/core/components/pagination/view-type.enum';
import {ColumnModel} from '@app/core/components/pagination/column.model';
import {FormControl, FormGroup} from '@angular/forms';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {UserViewSettingsResource} from '@app/core/resource/user-view-settings.resource';
import {UserViewSettingsDto} from '@app/core/resource-dto/user-view-settings';
import {KeyValue} from '@angular/common';
import QueryResponse = UserViewSettingsDto.QueryResponse;

@Component({
  selector: 'app-user-view-setting',
  templateUrl: './user-view-setting.component.html',
  styleUrls: ['./user-view-setting.component.scss']
})
export class UserViewSettingComponent implements OnInit, OnDestroy {

  private readonly ngDestroy: Subject<void> = new Subject<void>();

  @Input()
  private view: ViewType;

  @Input()
  columns: { [key: string]: ColumnModel };

  public form: FormGroup = new FormGroup({});

  constructor(private userViewSettingsResource: UserViewSettingsResource) {
  }

  ngOnInit(): void {
    this.initForm();
    this.loadSettings();

    this.form?.valueChanges.pipe(takeUntil(this.ngDestroy)).subscribe(values => {
      const response = Object.keys(values).filter(key => !values[key]);
      this.userViewSettingsResource.update({hiddenColumns: response}, null, {view: this.view})
        .then(_ => {
        })
        .catch(e => console.error('User view setting save error: ', e));
    });
  }

  private initForm() {
    Object.keys(this.columns).map(col => {
      this.form.setControl(col, new FormControl(this.columns[col].enabled));
    });
  }

  private updateInitialSettings() {
    const response = Object.keys(this.columns).filter(key => !this.columns[key].enabled);

    this.userViewSettingsResource.update({hiddenColumns: response}, null, {view: this.view})
      .then(_ => {
        response.forEach(v => {
          this.form.get(v).patchValue(false, {emitEvent: false});
        });
      })
      .catch(e => console.error('Initial settings update error: ', e));
  }

  private loadSettings() {
    this.userViewSettingsResource.get({view: this.view}).then((response: QueryResponse) => {
      if (!!response?.hiddenColumns?.length) {
        Object.keys(this.columns).map(key => {
          this.columns[key].enabled = !response.hiddenColumns.includes(key);
          this.form.get(key).patchValue(!response.hiddenColumns.includes(key), {emitEvent: false});
        });
      } else {
        this.updateInitialSettings();
      }
    });
  }

  toggle(key: string) {
    this.columns[key].enabled = !this.form.get(key).value;
    this.form.get(key).setValue(!this.form.get(key).value);
  }

  public ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  public columnOrder = (a: KeyValue<string, any>, b: KeyValue<string, any>): number => {
    if (a.value.order < b.value.order) {
      return -1;
    } else if (a.value.order > b.value.order) {
      return 1;
    } else {
      return 0;
    }
  }
}
