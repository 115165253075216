<div class="mobile-menu-left-overlay"></div>
<nav class="side-menu">
    <section>
        <ul class="side-menu-list">
            <li class="blue" *ngxPermissionsOnly="'application.workorder'">
                <a data-toggle="collapse" href="#portalApplications">
                    <em class="fa fa-calendar" ></em>
                    <span class="lbl">Töötaotlused</span>
                    <span class="caret" style="color: grey"></span>
                </a>
                <div class="collapse show" id="portalApplications" style="padding-left: 30px; padding-right: 5px">
                    <a [routerLink]="['/orders']" routerLinkActive="active">
                        <em class="glyphicon glyphicon-list-alt"></em>
                        <span class="lbl">Töötaotlused</span>
                    </a>
                    <a [routerLink]="['/inspections']" routerLinkActive="active" *ngxPermissionsOnly="[
                      'inspection.access.all',
                      'inspection.access.property-inspections',
                      'inspection.access.regional-property-inspections',
                      'inspection.access.client-own-building-inspections',
                      'th.access.records-north',
                      'th.access.records-south',
                      'th.access.records-east',
                      'th.access.records-west',
                      'hk.access.records-north',
                      'hk.access.records-south',
                      'hk.access.records-east',
                      'hk.access.records-west',
                      'tt.access.records-north',
                      'tt.access.records-south',
                      'tt.access.records-east',
                      'tt.access.records-west',
                      'inspection.access.client-inspections',
                      'inspection.access.same-governance-area-inspections'
                      ]">
                        <em class="glyphicon glyphicon-ok"></em>
                        <span class="lbl">Ülevaatused</span>
                    </a>
                    <a [routerLink]="['/acts']" routerLinkActive="active" *ngxPermissionsOnly="[
                    'act.access.all-acts',
                    'act.access.company-acts',
                    'act.access.property-acts',
                    'act.access.regional-property-acts',
                    'access.records-rkik',
                    'th.access.records-north',
                    'th.access.records-south',
                    'th.access.records-east',
                    'th.access.records-west',
                    'hk.access.records-north',
                    'hk.access.records-south',
                    'hk.access.records-east',
                    'hk.access.records-west',
                    'tt.access.records-north',
                    'tt.access.records-south',
                    'tt.access.records-east',
                    'tt.access.records-west'
                    ]">
                        <em class="glyphicon glyphicon-euro"></em>
                        <span class="lbl">Aktid</span>
                    </a>
                    <a [routerLink]="['/contracts']" routerLinkActive="active" *ngxPermissionsOnly="[
                    'maintenance-contract.access.all-contracts',
                    'maintenance-contract.access.company-contracts',
                    'maintenance-contract.access.property-contracts',
                    'maintenance-contract.access.regional-property-contracts',
                    'access.records-contractor-project-manager',
                    'access.records-contractor-dispatcher',
                    'access.records-contractor-works-manager',
                    'access.records-rkik',
                    'access.records-prison-tallinn',
                    'access.records-prison-tartu',
                    'access.records-prison-viru',
                    'access.records-all-except-prison',
                    'th.access.records-north',
                    'th.access.records-south',
                    'th.access.records-east',
                    'th.access.records-west',
                    'hk.access.records-north',
                    'hk.access.records-south',
                    'hk.access.records-east',
                    'hk.access.records-west',
                    'tt.access.records-north',
                    'tt.access.records-south',
                    'tt.access.records-east',
                    'tt.access.records-west'
                    ]">
                        <em class="glyphicon glyphicon-duplicate"></em>
                        <span class="lbl">Korrashoiuteenuste lepingud</span>
                    </a>
                    <a [routerLink]="['/buildings']" routerLinkActive="active" *ngxPermissionsOnly="['building-user.manage']">
                        <em class="font-icon font-icon font-icon-build"></em>
                        <span class="lbl">Hooned</span>
                    </a>
                    <a [routerLink]="['/admin']" routerLinkActive="active" *ngxPermissionsOnly="['admin.access']">
                        <em class="glyphicon glyphicon-cog"></em>
                        <span class="lbl">Administreerimine</span>
                    </a>
                </div>
            </li>
            <li class="blue-dirty" *ngxPermissionsOnly="'application.user-registry'">
                <a data-toggle="collapse" href="#usersApplications">
                    <em class="glyphicon glyphicon-user"></em>
                    <span class="lbl">Kasutajate register</span>
                    <span class="caret" style="color: grey"></span>
                </a>
                <div class="collapse" id="usersApplications" style="padding-left: 30px; padding-right: 5px">
                    <a href="{{config.getCoreAppUrl('userregistry', '/')}}">
                        <em class=" glyphicon glyphicon-user"></em>
                        <span class="lbl">Kasutajate nimekiri</span>
                    </a>
                    <a href="{{config.getCoreAppUrl('userregistry', '/')}}" *ngxPermissionsOnly="['company.manage']">
                        <em class=" glyphicon glyphicon-th-list"></em>
                        <span class="lbl">Ettevõtted</span>
                    </a>
                </div>
            </li>
            <li class="aquamarine" *ngxPermissionsOnly="'application.property-registry'">
                <a data-toggle="collapse" href="#propertyApplications">
                    <em class="fa fa-calendar"></em>
                    <span class="lbl">Kinnisturegister</span>
                    <span class="caret" style="color: grey"></span>
                </a>
                <div class="collapse" id="propertyApplications" style="padding-left: 30px; padding-right: 5px">
                    <a href="{{config.getCoreAppUrl('propertyregistry', '/')}}">
                        <em class="glyphicon glyphicon-home"></em>
                        <span class="lbl">Kinnistud</span>
                    </a>
                    <a href="{{config.getCoreAppUrl('propertyregistry', '/')}}">
                        <em class="glyphicon glyphicon-home"></em>
                        <span class="lbl">Hooned</span>
                    </a>
                    <a href="{{config.getCoreAppUrl('propertyregistry', '/')}}">
                        <em class="glyphicon glyphicon-list-alt"></em>
                        <span class="lbl">Seadmed</span>
                    </a>
                    <a href="{{config.getCoreAppUrl('propertyregistry', '/')}}">
                        <em class="glyphicon glyphicon-euro"></em>
                        <span class="lbl">Hinnapaketid</span>
                    </a>
                </div>
            </li>
            <li class="red" *ngxPermissionsOnly="'application.tuumik'">
                <a data-toggle="collapse" href="#costDivApplications">
                    <em class="fa fa-calendar"></em>
                    <span class="lbl">Kulude jagamine</span>
                    <span class="caret" style="color: grey"></span>
                </a>
                <div class="collapse" id="costDivApplications" style="padding-left: 30px; padding-right: 5px">
                    <header class="side-menu-title" style="padding-top:10px;">Kulude jagamine</header>
                    <a href="{{config.getCoreAppUrl('tuumikbilling', '/')}}">
                        <em class="glyphicon glyphicon-euro gold"></em>
                        <span class="lbl">Jooksvad kulud</span>
                    </a>
                    <a href="{{config.getCoreAppUrl('tuumikbilling', '/')}}">
                        <em class="glyphicon glyphicon-euro"></em>
                        <span class="lbl">Arveldamata kulud</span>
                    </a>
                    <a href="{{config.getCoreAppUrl('tuumikbilling', '/')}}">
                        <em class="glyphicon glyphicon-euro"></em>
                        <span class="lbl">Arved</span>
                    </a>
                    <div class="dropdown-divider"></div>
                    <header class="side-menu-title" style="padding-top:10px;">Ostuarved</header>
                    <a href="{{config.getCoreAppUrl('tuumikbilling', '/')}}">
                        <em class="glyphicon glyphicon-duplicate"></em>
                        <span class="lbl">Konteeringud</span>
                    </a>
                    <a href="{{config.getCoreAppUrl('tuumikbilling', '/')}}">
                        <em class="glyphicon glyphicon-duplicate"></em>
                        <span class="lbl">Ostuarved</span>
                    </a>
                    <a href="{{config.getCoreAppUrl('tuumikbilling', '/')}}">
                        <em class="glyphicon glyphicon-duplicate"></em>
                        <span class="lbl">XML adapterid</span>
                    </a>
                    <div class="dropdown-divider"></div>
                    <header class="side-menu-title" style="padding-top:10px;">Administreerimine</header>
                    <a href="{{config.getCoreAppUrl('tuumikbilling', '/')}}">
                        <em class="glyphicon glyphicon-duplicate"></em>
                        <span class="lbl">Üürnike jaotus</span>
                    </a>
                    <a href="{{config.getCoreAppUrl('tuumikbilling', '/')}}">
                        <em class="glyphicon glyphicon-duplicate"></em>
                        <span class="lbl">Kontoplaan</span>
                    </a>
                    <a href="{{config.getCoreAppUrl('tuumikbilling', '/')}}">
                        <em class="glyphicon glyphicon-duplicate"></em>
                        <span class="lbl">Üürilepingud</span>
                    </a>
                    <a href="{{config.getCoreAppUrl('tuumikbilling', '/')}}">
                        <em class="glyphicon glyphicon-duplicate"></em>
                        <span class="lbl">Näitude sisestamine</span>
                    </a>
                </div>
            </li>
            <li class="orange-red" *ngxPermissionsOnly="'application.contract-registry'">
                <a href="{{config.getCoreAppUrl('lepingud', '/')}}">
                    <em class=" glyphicon glyphicon-duplicate" ></em>
                    <span class="lbl">Lepinguregister</span>
                </a>
            </li>
            <li class="purple" *ngxPermissionsOnly="'application.projects'">
                <a href="{{config.getCoreAppUrl('projektid', '/')}}">
                    <em class=" glyphicon glyphicon-hdd" ></em>
                    <span class="lbl">Projektipank</span>
                </a>
            </li>
        </ul>
    </section>
</nav><!--.side-menu-->
