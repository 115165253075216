import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, fromEvent, Observable, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, startWith, takeUntil} from 'rxjs/operators';
import {OrientationType} from '@app/core/models';

@Injectable({providedIn: 'root'})
export class DeviceStateService implements OnDestroy {

    private readonly ngDestroy = new Subject<void>();
    public readonly screenWidth$: BehaviorSubject<number> = new BehaviorSubject(null);
    public readonly isMobileView$: Observable<boolean> = this.screenWidth$
        .pipe(map(width => width <= 1024), distinctUntilChanged(), debounceTime(10));
    public readonly orientation$ = fromEvent(window, 'resize')
        .pipe(startWith('portrait'), map(_ => this.getOrientation()));

    public constructor() {
        this.onInit();
    }

    public onInit() {
        this.setScreenWidth(window.innerWidth);
        fromEvent(window, 'resize')
            .pipe(
                takeUntil(this.ngDestroy)
            ).subscribe((evt: any) => {
            this.setScreenWidth(evt.target.innerWidth);
        });
    }

    private setScreenWidth(width: number): void {
        this.screenWidth$.next(width);
    }

    public ngOnDestroy(): void {
        this.ngDestroy.next();
        this.ngDestroy.complete();
    }

    private getOrientation(): OrientationType {
        return window.matchMedia('(orientation: landscape)').matches ? 'landscape' : 'portrait';
    }
}
