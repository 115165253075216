import {ErrorHandler, Injectable} from '@angular/core';
import {LoggerService} from './logger.service';

@Injectable()
export class ErrorHandlerService implements ErrorHandler {

    public constructor(private logger: LoggerService) {}

    public handleError(error: Error): void {
        console.error(error);
        this.logger.error(error?.message, error?.stack);
    }
}
