import {ScreenCoordinates} from './screen-coordinates.interface';
import {SwipeHelpers as Helper} from './swipe.helpers';

export class SwipeState {

    private coordinates?: ScreenCoordinates;

    public get hasData(): boolean {
        return Helper.hasValidCoordinates(this.coordinates);
    }

    public resetState(): void {
      this.coordinates = undefined;
    }

    public setState(event: TouchEvent): void {
        this.coordinates = Helper.getScreenCoordinates(event);
    }

    public length(other: SwipeState): number | undefined {
        if (!Helper.hasValidCoordinates(this?.coordinates, other?.coordinates)) return undefined;

        const dx = other.coordinates.x - this.coordinates.x;
        const dy = other.coordinates.y - this.coordinates.y;

        return Math.hypot(dx, dy);
    }

    public angleRadians(other: SwipeState): number | undefined {
        if (!Helper.hasValidCoordinates(this?.coordinates, other?.coordinates) ) return undefined;

        const dx = other.coordinates.x - this.coordinates.x;
        const dy = other.coordinates.y - this.coordinates.y;

        return Math.atan2(dy, dx);
    }
}
