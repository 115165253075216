export class HtmlElementUtils {

    private static INTERACTABLE_ELEMENTS = [
        HTMLInputElement,
        HTMLButtonElement,
        HTMLTextAreaElement,
        HTMLSelectElement,
        HTMLDataListElement,
        HTMLOptGroupElement,
        HTMLFieldSetElement,
        HTMLAnchorElement
    ];

    public static blurActiveInteractableElement(): void {
        HtmlElementUtils.blurInteractableElement(document?.activeElement);
    }

    public static blurInteractableElement(element: Element): void {
        if (!HtmlElementUtils.isInteractableElement(element)) return;

        (element as HTMLElement).blur();
    }

    public static isInteractableElement(element: Element): boolean {
        if (!element) return false;
        if (!(element instanceof HTMLElement)) return false;
        if (HtmlElementUtils.INTERACTABLE_ELEMENTS.some(x => element instanceof x)) return true;

        return element.hasAttribute('contentEditable');
    }
}
