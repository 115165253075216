import {NgClass} from '@angular/common';
import {FormControl} from '@angular/forms';
import {Component, HostListener, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild} from '@angular/core';
import {BehaviorSubject, merge, Subject} from 'rxjs';
import {distinctUntilChanged, map, takeUntil} from 'rxjs/operators';
import {PopoverDirective} from 'ngx-bootstrap/popover';
import * as _ from 'lodash';
import {MultiselectFilterOption} from './quick-filter-multiselect.option';
import {QuickFilterMultiselectType} from './quick-filter-multiselect.type';

@Component({
    // tslint:disable-next-line:component-selector
    selector: '[quickFilterMultiselect]',
    templateUrl: './quick-filter-multiselect.component.html'
})
export class QuickFilterMultiselectComponent implements OnChanges, OnDestroy {

    @Input()
    public type: QuickFilterMultiselectType = 'md';

    @Input()
    public disabled = false;

    @Input()
    public containerClass?: NgClass['ngClass'];

    @Input()
    public maxWidth = '6.25rem';

    @Input()
    public minWidth = '6.25rem';

    @Input()
    public allowClear = false;

    @Input()
    public clearText = 'Puudub';

    @Input()
    public closeOnClear = true;

    @Input()
    public closeOnSelect = true;

    @Input()
    public clearOnAllSelected = false;

    @Input()
    public innerFormControl!: FormControl;

    @Input()
    public options!: MultiselectFilterOption[];

    @ViewChild('popover')
    public set setPopover(popover: PopoverDirective) {
        this.popover = popover;
        if (!popover) return;

        popover.onHidden.subscribe(__ => this.updateFormGroup());
    }
    public popover: PopoverDirective;

    private readonly ngDestroy = new Subject<void>();
    private readonly ngChanges = new Subject<void>();

    private readonly currentOptionsSubject = new BehaviorSubject<MultiselectFilterOption[]>([]);
    public readonly currentOptions$ = this.currentOptionsSubject.asObservable()
        .pipe(takeUntil(this.ngDestroy), distinctUntilChanged());

    public readonly isActive$ = this.currentOptions$
        .pipe(takeUntil(this.ngDestroy), map(x => !!x?.length));
    public readonly selectionText$ = this.currentOptions$
        .pipe(takeUntil(this.ngDestroy), map(x => x?.map(y => y.name)?.join(', ')));

    @HostListener('window:scroll', ['$event'])
    public onScroll(__: Event): void {
        if (!this.popover) return;
        if (!this.popover.isOpen) return;

        this.popover.hide();
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (!!changes.innerFormControl) {
            this.ngChanges.next();
            this.listenToFormControl();
        }
        if (!!changes.options) {
            this.updateSelection();
        }
    }

    public ngOnDestroy() {
        this.ngChanges.next();
        this.ngChanges.complete();
        this.ngDestroy.next();
        this.ngDestroy.complete();
    }

    public select(option: MultiselectFilterOption): void {
        const current = [...this.currentOptionsSubject.value];
        const index = current.indexOf(option);

        if (index > -1) {
            current.splice(index, 1);
        } else {
            current.push(option);
        }

        this.currentOptionsSubject.next(current);
        if (!this.popover) return;
        if (!this.closeOnSelect) return;

        this.popover.hide();
    }

    public clear(): void {
        this.currentOptionsSubject.next([]);

        if (!this.popover) return;
        if (!this.closeOnClear) return;

        this.popover.hide();
    }

    private listenToFormControl(): void {
        if (!this.innerFormControl) return;

        this.innerFormControl.valueChanges
            .pipe(takeUntil(merge(this.ngChanges, this.ngDestroy)))
            .subscribe({next: __ => this.updateSelection()});
    }

    private updateSelection(): void {
        if (!this.innerFormControl || !this.options) {
            this.currentOptionsSubject.next([]);

            return;
        }

        const currentValues: string[] = this.innerFormControl.value || [];
        const filtered = this.options.filter(x => currentValues.includes(x.value));

        if (filtered.length === 0) {
            this.currentOptionsSubject.next([]);
        } else if (this.clearOnAllSelected && filtered.length === this.options.length) {
            this.currentOptionsSubject.next([]);
        } else {
            this.currentOptionsSubject.next(filtered);
        }
    }

    private updateFormGroup(): void {
        const selection = this.currentOptionsSubject.value?.map(x =>  x.value);
        const newValue = !!selection?.length ? selection : undefined;
        const oldValue = this.innerFormControl.value;
        if (_.isEqual(newValue, oldValue)) return;

        this.innerFormControl.setValue(!!selection?.length ? selection : undefined);
    }
}
