import {OnDestroy} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, takeUntil, startWith} from 'rxjs/operators';
import * as _ from 'lodash';
import {PageModel} from '@app/core/components/pagination/page.model';
import {TableModel} from '@app/core/components/pagination/table.model';
import {RowAbstract} from '@app/core/components/pagination/row.abstract';
import {MetadataDto} from '@app/core/resource-dto/metadata';
import PagedRequest = MetadataDto.PagedRequest;

export abstract class PaginatedList<P extends PagedRequest, R extends RowAbstract> implements OnDestroy {

    public readonly ngDestroy = new Subject<void>();
    protected readonly filterSubject = new BehaviorSubject<any>({});
    public readonly pageSource = new BehaviorSubject<PageModel>(PageModel.create().setOffset(0).setLimit(20));
    protected readonly tableSource = new BehaviorSubject<TableModel<R>>(TableModel.create<R>());

    public readonly filter$ = this.filterSubject.asObservable()
        .pipe(takeUntil(this.ngDestroy), debounceTime(10), distinctUntilChanged());
    public readonly page$ = this.pageSource.asObservable()
        .pipe(takeUntil(this.ngDestroy), distinctUntilChanged());
    public readonly pageNumber$ = this.page$
        .pipe(map(x => ((x.getOffset() || 0)) + 1), startWith(1), distinctUntilChanged());
    public readonly pageSize$ = this.page$
        .pipe(map(x => x.getLimit() || 20), startWith(20), distinctUntilChanged());
    public readonly query$: Observable<any> = combineLatest([this.filter$, this.page$])
        .pipe(takeUntil(this.ngDestroy), distinctUntilChanged(), map(query => this.mapQuery(query)));
    public readonly table$ = this.tableSource.asObservable().pipe(takeUntil(this.ngDestroy));

    public get currentPage(): PageModel {
        return this.pageSource.value;
    }

    public get currentTable(): TableModel<R> {
        return this.tableSource.value;
    }

    public ngOnDestroy(): void {
        this.ngDestroy.next();
        this.ngDestroy.complete();
    }

    public setFilter(value?: any): void {
        this.pageSource.next(this.currentPage.copy().setOffset(0));
        this.filterSubject.next(Object.assign({}, value));
    }

    public setFilterValue(key: string, value: any): void {
        const current = this.filterSubject.getValue();
        const copy = _.cloneDeep(current);
        copy[key] = value;
        this.pageSource.next(this.currentPage.copy().setOffset(0));
        this.filterSubject.next(copy);
    }

    public refreshRows(): void {
        this.tableSource.value.refreshRows();
    }

    public load(): void {
        this.pageSource.next(this.currentPage.copy().setOffset(0));
    }

    public setPage(page: PageModel): void {
        this.pageSource.next(this.currentPage.copy().setLimit(page.limit).setOffset(page.offset));
    }

    public setSort(sort: any): void {
        this.pageSource.next(this.currentPage.copy().setSort(sort).setOffset(0));
    }

    public setLimit(size: number): void {
        this.pageSource.next(this.currentPage.copy().setLimit(size).setOffset(0));
    }

    protected abstract mapQuery([filter, page]: [any, PageModel]): P;

    protected abstract query(request: any): void;
}
