import {Directive, ElementRef} from '@angular/core';

// tslint:disable-next-line:directive-selector
@Directive({selector: '[swipeableAnimationArea]'})
export class SwipeableAnimationAreaDirective {

    public readonly nativeElement: HTMLElement;

    public constructor(element: ElementRef) {
        this.nativeElement = element?.nativeElement;
    }
}
