<nav class="navbar navbar-general-page">

    <ul routerLinkActive="parent_visible">
        <li class="nav-item" routerLinkActive="active">
            <a routerLink="/orders" class="nav-link">
                Töötaotlused
            </a>
        </li>
        <li class="nav-item" routerLinkActive="active" *ngxPermissionsOnly="['order.access.warranty']">
            <a routerLink="/warranty" class="nav-link">
                Garantii
            </a>
        </li>
        <li class="nav-item"
            routerLinkActive="active"
            *ngxPermissionsOnly="[
          'inspection.access.all',
          'inspection.access.property-inspections',
          'inspection.access.regional-property-inspections',
          'inspection.access.client-own-building-inspections',
          'inspection.access.company-inspections',
          'th.access.records-north',
          'th.access.records-south',
          'th.access.records-east',
          'th.access.records-west',
          'hk.access.records-north',
          'hk.access.records-south',
          'hk.access.records-east',
          'hk.access.records-west',
          'tt.access.records-north',
          'tt.access.records-south',
          'tt.access.records-east',
          'tt.access.records-west',
          'inspection.access.client-inspections',
          'inspection.access.same-governance-area-inspections'
        ]">
            <a routerLink="/inspections" routerLinkActive="active" class="nav-link">
                Ülevaatused
                <app-navbar-badge [amount]="(badgeSource | async)?.inspections"
                                  [innerClass]="(badgeSource | async)?.inspectionClass"></app-navbar-badge>
            </a>
        </li>

        <li class="nav-item" routerLinkActive="active">
            <a routerLink="/messages-user" class="nav-link">
                <div class="d-flex align-items-center">
                    <span>Teavitused</span>
                    <span class="icon bell"></span>
                    <span>
          <app-navbar-badge [amount]="(badgeSource | async)?.messages"
                            [innerClass]="(badgeSource | async)?.messagesClass"></app-navbar-badge>
          </span>
                </div>
            </a>
        </li>
    </ul>

    <ul routerLinkActive="parent_visible" *ngxPermissionsOnly="['appliance-rule.access']">
        <li class="nav-item" routerLinkActive="active">
            <a routerLink="/appliance/schedule/range"
               [queryParams]="scheduleRangeParams$ | async"
               class="nav-link">
                Hooldusgraafik
            </a>
            <a routerLink="/appliance/schedule" class="d-none"></a>
        </li>
        <li class="nav-item" routerLinkActive="active">
            <a class="nav-link"
               routerLink="/appliance/plan/range"
               [queryParams]="planRangeParams$ | async">
                Hoolduskava
            </a>
            <a routerLink="/appliance/plan" class="d-none"></a>
            <a routerLink="/appliance/plan/list" class="d-none"></a>
        </li>
        <li class="nav-item" routerLinkActive="active">
            <a class="nav-link" routerLink="/appliance/orders">
                Hoolduspäevik
            </a>
        </li>
    </ul>

    <ul routerLinkActive="parent_visible">
        <li class="nav-item"
            routerLinkActive="active"
            *ngxPermissionsOnly="[
                  'act.access.all-acts',
                  'act.access.client-acts',
                  'act.access.company-acts',
                  'act.access.property-acts',
                  'act.access.regional-property-acts',
                  'access.records-contractor-act-specialist',
                  'access.records-rkik',
                  'th.access.records-north',
                  'th.access.records-south',
                  'th.access.records-east',
                  'th.access.records-west',
                  'hk.access.records-north',
                  'hk.access.records-south',
                  'hk.access.records-east',
                  'hk.access.records-west',
                  'tt.access.records-north',
                  'tt.access.records-south',
                  'tt.access.records-east',
                  'tt.access.records-west'
        ]">
            <a routerLink="/acts/regular" routerLinkActive="active" class="nav-link">
                Aktid
                <app-navbar-badge [amount]="(badgeSource | async)?.acts"></app-navbar-badge>
            </a>
        </li>
        <li class="nav-item"
            routerLinkActive="active"
            *ngxPermissionsOnly="[
          'act.access.all-quickacts',
          'act.access.property-manager-quickacts'
        ]">
            <a routerLink="/acts/quick" routerLinkActive="active" class="nav-link">
                Kiiraktid
            </a>
        </li>
    </ul>

    <ul routerLinkActive="parent_visible">
        <li class="nav-item"
            routerLinkActive="active"
            *ngxPermissionsOnly="[
          'maintenance-contract.access.all-contracts',
          'maintenance-contract.access.company-contracts',
          'maintenance-contract.access.property-contracts',
          'maintenance-contract.access.regional-property-contracts',
          'access.records-contractor-project-manager',
          'access.records-contractor-dispatcher',
          'access.records-contractor-works-manager',
          'access.records-rkik',
          'access.records-prison-tallinn',
          'access.records-prison-tartu',
          'access.records-prison-viru',
          'access.records-all-except-prison',
          'th.access.records-north',
          'th.access.records-south',
          'th.access.records-east',
          'th.access.records-west',
          'hk.access.records-north',
          'hk.access.records-south',
          'hk.access.records-east',
          'hk.access.records-west',
          'tt.access.records-north',
          'tt.access.records-south',
          'tt.access.records-east',
          'tt.access.records-west'
        ]">
            <a routerLink="/contracts" routerLinkActive="active" class="nav-link">
                Korrashoiuteenuste lepingud
            </a>
        </li>
        <li class="nav-item"
            routerLinkActive="active"
            *ngxPermissionsOnly="['construction-contract.access']">
            <a class="nav-link" routerLink="/construction-contracts">
                Ehituslepingud
            </a>
        </li>
        <li class="nav-item"
            routerLinkActive="active"
            *ngxPermissionsOnly="['building-user.manage']">
            <a class="nav-link" routerLink="/buildings">
                Hooned
            </a>
        </li>
        <li class="nav-item"
            routerLinkActive="active"
            *ngxPermissionsOnly="['order-report.access.all']">
            <a class="nav-link" routerLink="/reports">
                Aruanne
            </a>
        </li>
        <li class="nav-item" routerLinkActive="active" *ngxPermissionsOnly="['messages.access.all']">
            <a routerLink="/messages" class="nav-link">
                Teavitused
            </a>
        </li>
    </ul>

    <div dropdown
         class="submenu submenu-position-in-nav"
         placement="bottom right"
         *ngxPermissionsOnly="['admin.access', 'role-permission.manage', 'permission-permission.manage']">
        <span class="submenu-hover"></span>
        <button dropdownToggle
                id="button-basic"
                class="btn btn-link">
            <span class="icon icon_more-info-primary"></span>
        </button>

        <ul *dropdownMenu
            id="dropdown-alignment"
            class="dropdown-menu dropdown-menu-right"
            role="menu"
            aria-labelledby="button-alignment">
            <li role="menuitem" *ngxPermissionsOnly="['admin.access']">
                <a class="dropdown-item" (click)="openSyncModal()">
                    <em class="fas fa-sync-alt"></em>
                    Sünkimine
                </a>
            </li>
            <li class="divider dropdown-divider"></li>
            <li role="menuItem">
                <a class="dropdown-item" (click)="openNotificationModal()">
                    <em class="fas fa-edit"></em>
                    Koosta teavitus
                </a>
            </li>
            <li role="menuItem">
                <a class="dropdown-item" (click)="openSendProjectManagerNotificationModal()">
                    <em class="fas fa-envelope"></em>
                    Kiri töövõtja PJ-le
                </a>
            </li>
            <li role="menuitem" *ngxPermissionsOnly="['admin.access']">
                <a class="dropdown-item" [routerLink]="'public-api'">
                    <em class="fas fa-key"></em>
                    Avaliku api ligipääsud
                </a>
            </li>
            <li role="menuitem" *ngxPermissionsOnly="['role-permission.manage']">
                <a class="dropdown-item" [routerLink]="'roles'">
                    <em class="fas fa-cog"></em>
                    Rolli õigused
                </a>
            </li>
            <li role="menuitem" *ngxPermissionsOnly="['permission-permission.manage']">
                <a class="dropdown-item" [routerLink]="'permissions'">
                    <em class="fas fa-cog"></em>
                    Õiguste seosed
                </a>
            </li>
            <li role="menuitem" *ngxPermissionsOnly="['admin.access']">
                <a class="dropdown-item" (click)="openArchiveModal()">
                    <em class="fas fa-trash"></em>
                    Arhiveerimine
                </a>
            </li>
            <li role="menuitem" *ngxPermissionsOnly="['instructions.manage']">
                <a class="dropdown-item" (click)="openInstructionEditModal()">
                    <em class="fas fa-book"></em>
                    Kasutusjuhendid
                </a>
            </li>
        </ul>
    </div>

</nav>
