import {Component, Input} from '@angular/core';
import {DeviceStateService} from '@app/core/services/device';

@Component({
    selector: 'app-generic-badge',
    templateUrl: './generic-badge.component.html'
})
export class GenericBadgeComponent {

    @Input()
    public text: string;

    @Input()
    public classes: string[] = [];

    public readonly isMobileView$ = this.deviceStateService.isMobileView$;

    public constructor(private readonly deviceStateService: DeviceStateService) {}
}
