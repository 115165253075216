import {Component, Input} from '@angular/core';

@Component({
    // tslint:disable-next-line:component-selector
    selector: '[quickFilterRadio]',
    templateUrl: './quick-filter-radio.component.html'
})
export class QuickFilterRadioComponent {

    @Input()
    public tooltipContent: string;

    @Input()
    public value?: string;

    @Input()
    public count?: number;

    @Input()
    public disabled = false;

    @Input()
    public type: 'primary' | 'readonly' = 'primary';

    public get hasCount(): boolean {
        return ![null, undefined].includes(this.count);
    }
}
