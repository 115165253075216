<div class="modal-container-white modal-sm">
  <div class="modal-header">
    <h4 id="dialog-sizes-name1" class="modal-title pull-left">{{title}}</h4>
    <button type="button" class="close pull-right" (click)="close()" aria-label="Close">
      <span class="submenu-hover"></span>
      <span class="icon icon_close"></span>
    </button>
  </div>

  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <div class="alert alert-info">
          <h6 class="font-weight-bold" *ngIf="message">{{message}}</h6>
          <p class="mt-1" *ngIf="description">{{description}}</p>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button (click)="close()" type="button" class="btn btn-outline-primary mr-4">{{closeLabel}}</button>
  </div>
</div>
