import {ScreenCoordinates, SwipeDirection} from '@app/core/directive';

export class SwipeHelpers {

    public static convertAngleToDirection(angle: number): SwipeDirection | undefined {
        if (!angle) return undefined;

        const rotatedAngle = ((angle + Math.PI / 4) + 2 * Math.PI) % (2 * Math.PI);
        if (rotatedAngle >= 0 && rotatedAngle < Math.PI / 2) return 'right';
        if (rotatedAngle >= Math.PI / 2 && rotatedAngle < Math.PI) return 'down';
        if (rotatedAngle >= Math.PI && rotatedAngle < 3 * Math.PI / 2) return 'left';

        return 'up';
    }

    public static getScreenCoordinates(event: TouchEvent): ScreenCoordinates | undefined {
        if (!event?.changedTouches?.length) return undefined;

        const touch = event.changedTouches[0];
        if ([touch?.clientX, touch?.clientY].includes(undefined)) return undefined;

        return {
            x: touch.clientX,
            y: touch.clientY
        };
    }

    public static hasValidCoordinates(...coordinatesArray: ScreenCoordinates[]): boolean {
        return coordinatesArray.every(x => SwipeHelpers.hasValidCoordinatesInner(x));
    }

    private static hasValidCoordinatesInner(coordinates: ScreenCoordinates): boolean {
        if (coordinates == null) return false;
        if ([null, undefined].includes(coordinates.x)) return false;
        if (isNaN(coordinates.x)) return false;
        if (!isFinite(coordinates.x)) return false;
        if ([null, undefined].includes(coordinates.y)) return false;
        if (isNaN(coordinates.y)) return false;

        return isFinite(coordinates.y);
    }
}
