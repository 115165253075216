<ng-container *ngIf="isAccident; then accident"></ng-container>
<ng-container *ngIf="!isAccident && isRepair; then repair"></ng-container>
<ng-container *ngIf="!isAccident && !isRepair; then defaultDate"></ng-container>
<ng-template #accident>
  <span *ngIf="isOverdue; else isNotOverDue"
      class="badge badge-warning badge-date"
      tooltip="Avariiline töö üle aja"
      container="body"
      containerClass="tooltip-fix"
      placement="top">
    <i class="icon exclamation icon-sm mr-1 icon-white"></i> + {{overdueDays}}p {{overdueHours}}h
  </span>
  <ng-template #isNotOverDue>
    <span class="text-nowrap text-warning d-flex align-items-center"
          tooltip="Avariiline töö"
          container="body"
          placement="top"
          containerClass="tooltip-fix">
                  <span class="icon exclamation d-block icon-sm pl-1"></span>
                  <span>{{value | date:'&nbsp;dd.MM.yyyy\'&nbsp;\'HH:mm'}}</span>
    </span>
  </ng-template>
</ng-template>

<ng-template #repair>
  <span *ngIf="isOverdue; else dueDateAccident" class="badge badge-primary no-wrap">
     + {{overdueDays}}p {{overdueHours}}h
  </span>
  <ng-template #dueDateAccident>
    <img *ngIf="value" class="mr-1" src="/assets/gfx/icons/construction.svg" width="auto" height="auto" alt=""> <span style="color: #649ABA">{{value ? (value | date:'dd.MM.yyyy\'&nbsp;\'HH:mm') : '-'}}</span>
  </ng-template>
</ng-template>
<ng-template #defaultDate>
  <span *ngIf="isOverdue; else dueDate" class="badge badge-danger no-wrap">+ {{overdueDays}}p {{overdueHours}}h</span>
  <ng-template #dueDate>
    <span>{{value ? (value | date:'dd.MM.yyyy\'&nbsp;\'HH:mm') : '-'}}</span>
  </ng-template>
</ng-template>
