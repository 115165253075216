<div class="works-link work-filter-link align-items-center"
     [ngClass]="innerClass"
     [class.filter-link-active]="active"
     [class.disabled]="disabled"
     (click)="toggleFilters.emit()">

    <button *ngIf="!active"
            class="btn btn-link works-link-label text-primary d-flex p-0 align-items-center"
            style="text-decoration:none;">
        <span class="icon icon_filter mr-1"></span>Filtreeri
    </button>

    <ng-container *ngIf="active">
        <h6 class="works-link-label d-flex align-items-center">Filtreeritud<em class="fas fa-pencil-alt pl-4"></em></h6>
        <h4 class="works-filtered-number text-primary"></h4>
    </ng-container>

</div>
