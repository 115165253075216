import {Injectable} from '@angular/core';
import {IResourceMethod, ResourceAction, ResourceHandler, ResourceRequestMethod} from '@ngx-resource/core';
import {ConfigService} from '@app/config/config.service';
import {AuthService} from '../services/auth.service';
import {AuthGuardedResource} from './auth-guarded.resource';
import {
    PermissionListRequest,
    PermissionResponse,
    SubPermissionListRequest,
    SubPermissionResponse, SubPermissionSaveRequest
} from '@app/core/resource-dto/permission/';

@Injectable({providedIn: 'root'})
export class PermissionRes extends AuthGuardedResource {

    constructor(
        protected requestHandler: ResourceHandler,
        protected authService: AuthService,
        protected config: ConfigService
    ) {
        super(requestHandler, authService, config);

        this.$setUrl(config.getBackendUrl('/permissions'));
    }

    @ResourceAction({
        expectJsonArray: false
    })
    query: IResourceMethod<PermissionListRequest, PermissionResponse>;

    @ResourceAction({
      expectJsonArray: false,
      path: '/{!permissionId}/sub-permissions',
      method: ResourceRequestMethod.Get
    })
    get: IResourceMethod<SubPermissionListRequest, SubPermissionResponse>;

    @ResourceAction({
      path: '/{!permissionId}/sub-permissions',
      method: ResourceRequestMethod.Put
    })
    save: IResourceMethod<SubPermissionSaveRequest, SubPermissionResponse>;
}
