<div class="modal-container-white modal-xl">
    <div class="modal-header">
        <h4 id="dialog-sizes-name1" class="modal-title pull-left">{{row.title}}</h4>
        <button type="button" class="close pull-right" (click)="close()" aria-label="Sulge">
            <span class="submenu-hover"></span>
            <span class="icon icon_close"></span>
        </button>
    </div>

    <div class="modal-body">
        <div class="row">
            <div class="col-12">
                <div class="d-flex">
                        <span class="text-danger mr-2">
                            {{row.type | classifier: 'MESSAGE_TYPE'}}
                        </span>
                    <span class="mr-2">{{row.createdAt ? (row.createdAt | date:'dd.MM.yyyy') : '-'}}</span>
                </div>
                <div class="alert alert-info white-space-pre-line">{{row.text}}</div>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <button (click)="close()" type="button" class="btn btn-outline-primary mr-4">Sulge</button>
    </div>
</div>
