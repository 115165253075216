import {Component, Input, ViewChild} from '@angular/core';
import {PopoverDirective} from 'ngx-bootstrap/popover';
import {OrderDto} from '@app/core/resource-dto/order/order';
import OrderComment = OrderDto.OrderComment;

@Component({
    selector: 'app-order-comment-popover',
    templateUrl: './order-comment-popover.component.html',
    styleUrls: ['./order-comment-popover.component.scss']
})
export class OrderCommentPopoverComponent {

    @ViewChild('popover', {read: PopoverDirective})
    public popover: PopoverDirective;

    @Input()
    public row: any;

    @Input()
    public comments: OrderComment[];
}
