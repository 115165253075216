<ng-template #popTemplate>
  <div class="custom-datepicker-container">
    <div class="datepicker-buttons">
      <button class="btn btn-light btn-sm" (click)="pop.hide(); selectToday()">Täna</button>
      <button class="btn btn-light btn-sm" (click)="pop.hide(); selectYesterday()">Eile</button>
      <button class="btn btn-light btn-sm" (click)="pop.hide(); selectLast7Days()">Viimased 7 päeva</button>
      <button class="btn btn-light btn-sm" (click)="pop.hide(); selectCurrentMonth()">Jooksev kuu</button>
      <button class="btn btn-light btn-sm" (click)="pop.hide(); selectPreviousMonth()">Eelmine kuu</button>
    </div>
    <ngb-datepicker outsideDays="hidden"
                    [displayMonths]="2"
                    [dayTemplate]="dayTemplate"
                    (select)="selectDate($event)">
    </ngb-datepicker>
    <ng-template #dayTemplate let-date let-focused="focused">
            <span class="custom-day"
                  [class.focused]="focused"
                  [class.range]="isRange(date)"
                  [class.faded]="isHovered(date) || isInside(date)"
                  (mouseenter)="hoveredNgbDate = date"
                  (mouseleave)="hoveredNgbDate = null">
              {{date.day}}
            </span>
    </ng-template>
  </div>
</ng-template>

<input #pop="bs-popover"
       class="form-control mt-2"
       placeholder="Vali kuupäev"
       container="body"
       placement="bottom"
       [popover]="popTemplate"
       [outsideClick]="true"
       [value]="getValue()">
