export class PageModel {

    public offset: number;
    public limit: number;
    public sort: any;
    public count: number;

    public static create(): PageModel {
        return new PageModel();
    }

    public copy(): PageModel {
        const that = PageModel.create();
        that.offset = this.offset;
        that.limit = this.limit;
        that.sort = this.sort;
        that.count = this.count;

        return that;
    }

    public setOffset(offset: number): PageModel {
        this.offset = offset;

        return this;
    }

    public setLimit(limit: number): PageModel {
        this.limit = limit;

        return this;
    }

    public setSort(sort: any): PageModel {
        this.sort = sort;

        return this;
    }

    public getOffset(): number {
        return this.offset;
    }

    public getLimit(): number {
        return this.limit;
    }

    public getSort(): any {
        return this.sort;
    }

    public getCount(): number {
        return this.count;
    }
}
