import {Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges} from '@angular/core';

@Directive({
  selector: '[appClassifierClass]'
})
export class ClassifierClassDirective implements OnChanges{
  @Input('appClassifierClass')
  classifierValue: string;

  private class: string;

  constructor(private renderer: Renderer2, private hostElement: ElementRef) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.classifierValue) {
      if (changes.classifierValue.previousValue) {
        this.renderer.removeClass(this.hostElement.nativeElement, this.class);
      }
      this.class = this.classifierValue.toLowerCase().replace(/_/g, '-');
      if (this.class) {
        this.renderer.addClass(this.hostElement.nativeElement, this.class);
      }
    }
  }
}
