import { Directive } from '@angular/core';

@Directive({
  selector: '[appUserLink]'
})
export class UserLinkDirective {

  constructor() { }

}
