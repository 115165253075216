import {MetadataDto} from '@app/core/resource-dto/metadata';

export namespace MessageDto {

  import IdAndName = MetadataDto.IdAndName;
  import IdCodeAndName = MetadataDto.IdCodeAndName;
  import PageMetaDataResponse = MetadataDto.PageMetaDataResponse;
  import PagedRequest = MetadataDto.PagedRequest;

  export class Message {
    id: number;
    title: string;
    text: string;
    type: MessageType;
    createdBy: IdAndName;
    createdAt: string;
    isPublished: boolean;
    roles: IdCodeAndName[];
  }

  export class MessagePageResponse {
    public metadata: PageMetaDataResponse;
    public data: Message[];
  }

  export class MessagePageRequest extends PagedRequest {

  }

  export class MessageRequest {
    title: string;
    text: string;
    type: MessageType;
    isPublished: boolean;
    roleIds: number[];
  }

  export class MessageUser {
    id: number;
    title: string;
    text: string;
    type: MessageType;
    createdBy: IdAndName;
    readTimestamp: string;
    createdAt: string;
    updatedAt: string;
  }

  export class MessageUserPageResponse {
    public metadata: PageMetaDataResponse;
    public data: MessageUser[];
  }

  export class MessageUserPageRequest extends PagedRequest {

  }

  export class MessageUserStatus {
    isRead: boolean;
  }

  export enum MessageType {
    NEWS = 'NEWS',
    NOTIFICATION = 'NOTIFICATION'
  }
}
