import {Breadcrumb} from '@app/core/components/navbar/breadcrumbs/breadcrumb';

export class MobileBreadcrumbsHelper {

    public static isVisible(appLevel: boolean, isMobileView: boolean, breadcrumb: Breadcrumb): boolean {
        if (!appLevel) return true;
        if (!isMobileView) return false;
        if (!breadcrumb) return false;

        return !!breadcrumb?.hasMobile;
    }
}
