import {ContentChild, Directive, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {SwipeHelpers} from './swipe.helpers';
import {SwipeState} from './swipe-state.model';
import {SwipeEvent} from './swipe-event.interface';
import {SwipeableAnimationAreaDirective} from './swipeable-animation-area.directive';

// tslint:disable-next-line:directive-selector
@Directive({selector: '[swipeable]'})
export class SwipeableDirective {

    private static readonly SWIPE_MIN_LENGTH_PX = 100;

    private readonly startState = new SwipeState();

    @Input()
    public swipeMaxDuration?: number;

    @Input()
    public swipeMinLength?: number;

    @Input()
    public animateDragLeft?: boolean;

    @Input()
    public animateDragRight?: boolean;

    @Input()
    public disabled?: boolean;

    @Output()
    public readonly swipe = new EventEmitter<SwipeEvent>();

    @ContentChild(SwipeableAnimationAreaDirective)
    public set entryTemplateDirective(directive: SwipeableAnimationAreaDirective) {
        this.animationArea = directive?.nativeElement;
    }
    private animationArea?: HTMLElement;

    @HostListener('touchstart', ['$event'])
    public touchStart($event: TouchEvent): void {
        this.handleSwipeStart($event);
    }

    @HostListener('touchmove', ['$event'])
    public touchMove($event: TouchEvent): void {
        this.handleSwipeMove($event);
    }

    @HostListener('touchend', ['$event'])
    public touchEnd($event): void {
        this.handleSwipeEnd($event);
    }

    private get minLength(): number {
        if (![null, undefined].includes(this.swipeMinLength)) return this.swipeMinLength;

        return SwipeableDirective.SWIPE_MIN_LENGTH_PX;
    }

    private handleSwipeStart(event: TouchEvent): void {
        if (this.disabled) return;

        this.startState.setState(event);
    }

    private handleSwipeMove(event: TouchEvent): void {
        if (this.disabled) return;
        if (!this.startState.hasData) return;

        const midState = new SwipeState();
        midState.setState(event);

        const length = this.startState.length(midState);
        if (length < this.minLength) {
            this.resetTransform();

            return;
        }

        const rad = this.startState.angleRadians(midState);
        const direction = SwipeHelpers.convertAngleToDirection(rad);
        if (direction === 'left' && this.animateDragLeft) {
            this.setTransformLeft();
        } else if (direction === 'right' && this.animateDragRight) {
            this.setTransformRight();
        }
    }

    private handleSwipeEnd(event: TouchEvent): void {
        if (this.disabled) return;

        const endState = new SwipeState();
        endState.setState(event);

        const length = this.startState.length(endState);
        if (length < this.minLength) return;

        const rad = this.startState.angleRadians(endState);
        const direction = SwipeHelpers.convertAngleToDirection(rad);

        this.swipe.next({length, rad, direction, event});
        this.startState.resetState();
        this.resetTransform();
    }

    private resetTransform(): void {
        this.animationArea?.classList.remove('swipe-left', 'swipe-right');
    }

    private setTransformLeft(): void {
        this.animationArea?.classList.add('swipe-right');
    }

    private setTransformRight(): void {
        this.animationArea?.classList.add('swipe-left');
    }
}
