import {FormControl} from '@angular/forms';
import {Component, Input, ViewChild} from '@angular/core';
import {NgbDate} from '@ng-bootstrap/ng-bootstrap';
import {PopoverDirective} from 'ngx-bootstrap/popover';
import * as moment from 'moment';
import {MomentNgbDateAdapter} from '@app/shared/services/moment-ngb-date-adapter.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'daterange-picker',
  templateUrl: './daterange-picker.component.html'
})
export class DaterangePickerComponent {

  @Input()
  public fromDate: FormControl;

  @Input()
  public toDate: FormControl;

  @ViewChild('pop')
  public popover: PopoverDirective;

  public hoveredNgbDate: NgbDate;

  public constructor(private ngbDateAdapter: MomentNgbDateAdapter) {}

  public selectDate(date: NgbDate): void {
    if (!this.fromDate.value && !this.toDate.value) {
      this.fromDate.setValue(this.ngbDateAdapter.toModel(date));
    } else if (this.fromDate.value && !this.toDate.value && this.ngbDateAdapter.toModel(date).isAfter(this.fromDate.value)) {
      this.toDate.setValue(this.ngbDateAdapter.toModel(date));
      this.popover?.hide();
    } else {
      this.toDate.setValue(null);
      this.fromDate.setValue(this.ngbDateAdapter.toModel(date));
    }
  }

  public selectToday(): void {
    this.fromDate.setValue(moment().startOf('day'));
    this.toDate.setValue(moment().startOf('day'));
  }

  public selectYesterday(): void {
    this.fromDate.setValue(moment().startOf('day').subtract(1, 'days'));
    this.toDate.setValue(moment().startOf('day').subtract(1, 'days'));
  }

  public selectLast7Days(): void {
    this.fromDate.setValue(moment().startOf('day').subtract(7, 'days'));
    this.toDate.setValue(moment().startOf('day'));
  }

  public selectCurrentMonth(): void {
    this.fromDate.setValue(moment().startOf('month'));
    this.toDate.setValue(moment().endOf('month'));
  }

  public selectPreviousMonth(): void {
    this.fromDate.setValue(moment().startOf('month').subtract(1, 'months'));
    this.toDate.setValue(moment().endOf('month').subtract(1, 'months'));
  }

  public isHovered(date: NgbDate): boolean {
    return this.fromDate.value && this.hoveredNgbDate && !this.toDate.value &&
      this.ngbDateAdapter.toModel(date).isAfter(this.fromDate.value) && date.before(this.hoveredNgbDate);
  }

  public isInside(date: NgbDate): boolean {
    return this.ngbDateAdapter.toModel(date).isAfter(this.fromDate.value) &&
      this.ngbDateAdapter.toModel(date).isBefore(this.toDate.value);
  }

  public isRange(date: NgbDate): boolean {
    return this.ngbDateAdapter.toModel(date).isSame(this.fromDate.value) ||
      this.ngbDateAdapter.toModel(date).isSame(this.toDate.value) ||
      this.isInside(date) || this.isHovered(date);
  }

  public getValue(): string {
    let output = '';
    if (!this.fromDate || !this.fromDate.value) return output;

    output += this.fromDate.value.format('DD.MM.YYYY');
    if (!this.toDate || !this.toDate.value) return output;
    if (this.fromDate.value === this.toDate.value) return output;

    return output + ' - ' + this.toDate.value.format('DD.MM.YYYY');
  }
}
