<button class="dropdown-toggle" id="dd-user-menu" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
    <span class="d-none d-sm-none d-md-inline-block">{{currentUserCompany?.user.name}}&nbsp;-&nbsp;{{currentUserCompany?.name}}</span>
    <img src="../../../../../work-order-client-bak/src/assets/img/avatar-2-64.png" alt="">
</button>
<div class="dropdown-menu dropdown-menu-right" aria-labelledby="dd-user-menu">

    <a class="dropdown-item" *ngFor="let userCompany of currentUser?.companies; let i = index" (click)="selectCompany(userCompany)">
        <span class="font-icon glyphicon glyphicon-user"></span>{{userCompany?.name}}
    </a>

    <ng-container *ngFor="let substitution of currentUser?.substitutions">
        <a class="dropdown-item" *ngFor="let userCompany of substitution.actingAsUser.companies"
           [class.font-weight-bold]="currentUser?.userCompany?.id == userCompany.id"
           (click)="selectCompany(userCompany)">
            <em class="font-icon fa fa-building-o"></em> {{ userCompany.name }} ({{ substitution?.actingAsUser?.forename }} {{ substitution?.actingAsUser?.surname }})
        </a>
    </ng-container>

    <div class="dropdown-divider"></div>
    <a class="dropdown-item" (click)="btnLogoutClicked()"><span class="font-icon glyphicon glyphicon-log-out"></span>Logi välja</a>
</div>
