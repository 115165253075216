import {DeviceType} from './device.type';

export class DeviceInformationUtils {

    public static hasTouchScreen(): boolean {
        const source: any = window.navigator;
        if ('maxTouchPoints' in source) {
            return source.maxTouchPoints > 0;
        } else if ('msMaxTouchPoints' in source) {
            return source.msMaxTouchPoints > 0;
        } else if (window?.matchMedia?.('(pointer: coarse)')) {
            return window.matchMedia('(pointer: coarse)').matches;
        }

        return false;
    }

    public static getDeviceType(): DeviceType {
        const userAgent = window.navigator.userAgent;
        if (DeviceInformationUtils.isMobileDevice(userAgent)) return 'mobile';
        if (DeviceInformationUtils.isTabletDevice(userAgent)) return 'mobile';

        return 'desktop';
    }

    private static isMobileDevice(userAgent: string): boolean {
        const regexTests = [/(Android)(.+)(Mobile)/i, /BlackBerry/i, /iPhone|iPod/i, /Opera Mini/i, /IEMobile/i];

        return regexTests.some((b) => userAgent.match(b));
    }

    public static isTabletDevice(userAgent: string): boolean {
        const regex = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/;

        return regex.test(userAgent.toLowerCase());
    }
}
