import {Injectable} from '@angular/core';
import {
  IResourceMethod,
  IResourceMethodStrict,
  ResourceAction,
  ResourceHandler,
  ResourceRequestMethod
} from '@ngx-resource/core';
import {ConfigService} from '@app/config/config.service';
import {AuthService} from '../services/auth.service';
import {AuthGuardedResource} from './auth-guarded.resource';
import {ViewType} from '@app/core/components/pagination/view-type.enum';
import {UserViewSettingsDto} from '@app/core/resource-dto/user-view-settings';

@Injectable({providedIn: 'root'})
export class UserViewSettingsResource extends AuthGuardedResource {

    constructor(
        protected requestHandler: ResourceHandler,
        protected authService: AuthService,
        protected config: ConfigService
    ) {
        super(requestHandler, authService, config);

        this.$setUrl(config.getBackendUrl('/settings'));
    }

  @ResourceAction({
    expectJsonArray: false,
    path: '/{view}',
  })
  get: IResourceMethod<{view: ViewType}, UserViewSettingsDto.QueryResponse>;

  @ResourceAction({
    expectJsonArray: false,
    path: '/{view}',
    method: ResourceRequestMethod.Put
  })
  update: IResourceMethodStrict<UserViewSettingsDto.UpdateRequest, any, {view: ViewType}, any>;
}
