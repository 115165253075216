import { Component } from '@angular/core';
import { ConfigService } from '@app/config/config.service';

@Component({
    selector: 'app-side-menu',
    templateUrl: './side-menu.component.html'
})
export class SideMenuComponent {

    constructor(protected config: ConfigService) {
    }
}
