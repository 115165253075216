import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'icon-button',
    templateUrl: './icon-button.component.html'
})
export class IconButtonComponent {

    @Input()
    public active!: boolean;

    @Input()
    public disabled!: boolean;

    @Output()
    public readonly doClick = new EventEmitter<MouseEvent>();

    public onClick(event: MouseEvent): void {
        if (this.disabled) return;

        this.doClick.emit(event);
    }
}
