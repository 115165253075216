import {Injectable} from '@angular/core';
import {timer} from 'rxjs';
import {distinctUntilChanged, map, shareReplay, startWith} from 'rxjs/operators';
import {Moment} from 'moment';
import * as moment from 'moment';

@Injectable({providedIn: 'root'})
export class TimerService {

    public readonly minuteTick$ = timer(0, 60 * 1000).pipe(
        map(_ => TimerService.getCurrentMinute()),
        distinctUntilChanged((x, y) => x.isSame(y)),
        startWith(TimerService.getCurrentMinute()),
        shareReplay(1)
    );

    public readonly dayTick$ = this.minuteTick$.pipe(
        map(_ => TimerService.getCurrentDay()),
        distinctUntilChanged((x, y) => x.isSame(y)),
        startWith(TimerService.getCurrentDay()),
        shareReplay(1)
    );

    public readonly weekTick$ = this.minuteTick$.pipe(
        map(_ => TimerService.getCurrentWeek()),
        distinctUntilChanged((x, y) => x.isSame(y)),
        startWith(TimerService.getCurrentWeek()),
        shareReplay(1)
    );

    public readonly monthTick$ = this.minuteTick$.pipe(
        map(_ => TimerService.getCurrentMonth()),
        distinctUntilChanged((x, y) => x.isSame(y)),
        startWith(TimerService.getCurrentMonth()),
        shareReplay(1)
    );

    private static getCurrentMinute(): Moment {
        return moment().startOf('minute');
    }

    private static getCurrentDay(): Moment {
        return moment().startOf('day');
    }

    private static getCurrentWeek(): Moment {
        return moment().startOf('week');
    }

    private static getCurrentMonth(): Moment {
        return moment().startOf('month');
    }
}
