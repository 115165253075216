import {RowAbstract} from '@app/core/components/pagination/row.abstract';

export class TableModel<T extends RowAbstract> {

  private count: number;
  private offset: number;
  private rows: T[];

  public static create<T>(): TableModel<any> {
    return new TableModel<T>();
  }

  public setCount(count: number): TableModel<any> {
    this.count = count;

    return this;
  }

  public setOffset(offset: number): TableModel<any> {
    this.offset = offset;

    return this;
  }

  public setRows(rows: T[]): TableModel<any> {
    this.rows = [...rows];

    return this;
  }

  public refreshRows(): TableModel<any> {
    this.rows = [...this.rows];

    return this;
  }

  public getCount(): number {
    return this.count;
  }

  public getOffset(): number {
    return this.offset;
  }

  public getRows(): T[] {
    return this.rows;
  }
}
