<ng-template #popoverTemplate>

    <ul class="popover-menu works-link-dropdown" role="menu">
        <li *ngFor="let option of options"
            class="popover-menu-item"
            [class.active]="(currentOption$ | async) === option"
            (click)="select(option)">
            {{option.name}}
        </li>
    </ul>

</ng-template>

<div #popover="bs-popover"
     class="works-link"
     placement="bottom left"
     containerClass="quick-popover-bg"
     container="body"
     [class.disabled]="disabled"
     [class.active]="(currentOption$ | async)?.value !== null"
     [tooltip]="tooltipContent"
     [popover]="popoverTemplate"
     [outsideClick]="true"
     [adaptivePosition]="false">
    <span class="works-link-label text-sm text-nowrap d-flex align-items-center">
        <ng-content></ng-content>
    </span>
    <h4 *ngIf="(currentOption$ | async) as option"
        class="works-filtered-number text-primary">
        {{option.name}}
    </h4>
</div>
