import {Injectable, OnDestroy} from '@angular/core';
import {Observable, ReplaySubject, Subject} from 'rxjs';
import {PermissionRes} from '@app/core/resource/permission.resource';
import {map, takeUntil} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {ConfigService} from '@app/config';
import {RolePermissionResponse, RolePermissionUpdateRequest} from '@app/core/resource-dto/role-permission';
import {Permission, PermissionResponse} from '@app/core/resource-dto/permission/response';
import {Role, RoleRequest} from '@app/core/resource-dto/role';
import {PagingUtils} from '@app/core/utils';

@Injectable()
export class RolesRequestService implements OnDestroy {

    private readonly ngDestroy: Subject<void> = new Subject<void>();
    private readonly roleSource = new ReplaySubject<Role[]>(1);
    private readonly rolePermissionSource = new ReplaySubject<RolePermissionResponse[]>(1);
    private readonly permissionSource = new ReplaySubject<Permission[]>(1);

    public readonly roles$ = this.roleSource.asObservable();
    public readonly rolePermissions$ = this.rolePermissionSource.asObservable();
    public readonly permissions$ = this.permissionSource.asObservable();

    public rolesLoaded = false;
    public permissionLoaded = false;
    public loadedRolePermission = null;

    constructor(
        private permissionRes: PermissionRes,
        private http: HttpClient,
        private config: ConfigService
    ) {
    }

    public loadRoles() {
        this.getRoles().pipe(takeUntil(this.ngDestroy)).subscribe(roles => {
            this.rolesLoaded = true;
            this.roleSource.next(roles);
        });
    }

    public getRoles(request?: RoleRequest): Observable<Role[]> {
        const url = this.config.getBackendUrl(`/roles`);
        const params = PagingUtils.getParams(request);
        const options = {withCredentials: true, params};

        return this.http.get<Role[]>(url, options)
            .pipe(map((response: Role[]) => response));
    }

    public getAllRolePermissions(roleId: number): Observable<RolePermissionResponse[]> {
        const url = this.config.getBackendUrl(`/roles/${roleId}/permissions`);
        const params = {roleId: roleId.toString()};
        const options = {withCredentials: true, params};

        return this.http.get<RolePermissionResponse[]>(url, options)
            .pipe(map((response: RolePermissionResponse[]) => response));
    }

    public updateRolePermission(roleId: number, request: RolePermissionUpdateRequest[]): Observable<RolePermissionResponse[]> {
        const url = this.config.getBackendUrl(`/roles/${roleId}/permissions`);
        const options = {withCredentials: true};

        return this.http.put<RolePermissionResponse[]>(url, request, options)
            .pipe(map((response: RolePermissionResponse[]) => response));
    }

    public loadRolePermissions(id: number) {
        this.getAllRolePermissions(id).pipe(takeUntil(this.ngDestroy)).subscribe((val: RolePermissionResponse[]) => {
                this.setRolePermissions(id, val);
            }
        );
    }

    public setRolePermissions(id: number, permissions: RolePermissionResponse[]) {
        this.loadedRolePermission = id;
        this.rolePermissionSource.next(permissions);
    }

    public loadPermissions() {
        this.permissionRes.query().then((result: PermissionResponse) => {
            this.permissionSource.next(result.permissions);
            this.permissionLoaded = true;
        });
    }

    ngOnDestroy(): void {
        this.ngDestroy.next();
        this.ngDestroy.complete();
    }
}
