<ng-template #popoverTemplate>
    <h6 class="text-dark">
        <ng-content></ng-content>
    </h6>
    <div>
        <input type="text"
               class="form-control text-wrap"
               [placeholder]="placeholder"
               [name]="name"
               [(ngModel)]="model"
               *ngIf="type === 'input'"
        />
        <textarea class="form-control"
                  rows="2"
                  [placeholder]="placeholder"
                  [name]="name"
                  [(ngModel)]="model"
                  *ngIf="type === 'textarea'"
        >
        </textarea>

        <div class="d-flex align-items-center justify-content-between mt-3">
            <button class="btn btn-outline-dark btn-sm" (click)="cancel()">Katkesta</button>
            <button class="btn btn-dark btn-sm" (click)="save()">Salvesta</button>
        </div>
    </div>
</ng-template>

<button #popover
        type="button"
        class="close ml-4"
        container="body"
        [popover]="popoverTemplate"
        [adaptivePosition]="false"
        [placement]="placement">
    <span class="submenu-hover submenu-hover-position"></span>
    <span class="icon pencil mr-1" aria-label="Muuda"></span>
</button>
