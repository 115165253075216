import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormService} from '@app/order/form/form.service';
import {TempFileService} from '@app/order/create/temp-file.service';
import {BuildingRes} from '@app/core/resource/building.resource';
import {FileService} from '@app/order/edit/file.service';
import {ManageService} from '@app/order/manage.service';
import {DatePipe} from '@angular/common';
import {CreateQuestService} from '@app/order/create-quest/create-quest.service';
import {NavbarService} from '@app/core/components/navbar/navbar.service';
import {CreateQuestActionsComponent} from '@app/order/create-quest/create-quest-actions.component';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
    templateUrl: './create-quest.component.html',
    styleUrls: ['./create-quest.component.scss'],
    providers: [
        CreateQuestService,
        FormService,
        TempFileService,
        BuildingRes,
        {provide: FileService, useExisting: TempFileService},
        ManageService,
        DatePipe
    ],
    entryComponents: [CreateQuestActionsComponent]
})
export class CreateQuestComponent implements OnInit, OnDestroy {

    private readonly ngDestroy = new Subject<void>();
    public readonly orderForm = this.formService.orderForm;
    public readonly isMinistryPage$ = this.createService.isMinistryPage$;

    @ViewChild('navActions', {static: true})
    public navTempalte: TemplateRef<any>;

    public constructor(
        private readonly createService: CreateQuestService,
        private readonly formService: FormService,
        private readonly navbarService: NavbarService
    ) {
        createService.isMinistryPage$.pipe(takeUntil(this.ngDestroy)).subscribe(isMinistryPage => {
            formService.changeToPublicForm(isMinistryPage);
        });
    }

    public get showFormErrors(): boolean {
        return this.createService.showFormErrors;
    }

    public get formSent(): boolean {
        return this.createService.formSent;
    }

    public get formErrors(): any {
        return this.createService.formErrors;
    }

    public ngOnDestroy(): void {
        this.ngDestroy.next();
        this.ngDestroy.complete();
        this.navbarService.setActionsTemplate(null);
    }

    public ngOnInit(): void {
        this.navbarService.setActionsTemplate(this.navTempalte);
    }

    public dismissErrors(): void {
        this.createService.showFormErrors = false;
    }

    public reset(): void {
        this.createService.reset();
    }
}
