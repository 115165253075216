<ng-template #popoverTemplate>

    <ul class="popover-menu works-link-dropdown" role="menu">

        <li *ngIf="allowClear"
            class="popover-menu-item"
            [class.active]="!(isActive$ | async)"
            (click)="clear()">
            <div [innerText]="clearText"></div>
        </li>

        <li *ngFor="let option of options"
            class="popover-menu-item"
            [class.active]="(currentOptions$ | async)?.includes(option)"
            (click)="select(option)">
            <div [innerText]="option.name"></div>
        </li>

    </ul>

</ng-template>

<div #popover="bs-popover"
     class="works-link no-padding"
     placement="bottom left"
     containerClass="quick-popover-bg"
     container="body"
     [style.max-width]="maxWidth"
     [style.min-width]="minWidth"
     [class.disabled]="disabled"
     [class.filter-link-active]="!!(isActive$ | async)"
     [ngClass]="containerClass"
     [popover]="popoverTemplate"
     [outsideClick]="true"
     [adaptivePosition]="false">

    <div class="works-link-inner-container"
         container="body"
         [tooltip]="selectionText$ | async"
         [delay]="250">

        <span class="works-link-label text-sm text-nowrap d-flex align-items-center"
              [class.works-link-label-sm]="type === 'sm'">
            <ng-content></ng-content>
        </span>

        <h4 class="works-filtered-number text-primary"
            [class.works-filtered-number-sm]="type === 'sm'"
            [innerText]="(selectionText$ | async) || clearText">
        </h4>

    </div>

</div>
