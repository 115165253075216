import {Component, Input} from '@angular/core';
import {ReplaySubject} from "rxjs";
import {BsModalRef} from "ngx-bootstrap/modal";
import {HttpStatusCode} from "@app/shared/constants";

@Component({
  selector: 'app-notification-modal',
  templateUrl: './notification-modal.component.html'
})
export class NotificationModalComponent {
  @Input()
  public title = 'Tähelepanu!';

  @Input()
  public message = 'Oled kindel?';

  @Input()
  public description;

  @Input()
  public closeLabel = 'Ok';

  @Input()
  public callback: (result: boolean) => void;

  private resultSource = new ReplaySubject<boolean>();
  constructor(private modalRef: BsModalRef) { }

  close() {
    this.resultSource.next(false);
    this.resultSource.complete();
    if (this.callback) {
      this.callback(false);
    }
    this.modalRef.hide();
  }
}
