import {Injectable, OnDestroy} from '@angular/core';
import {MessagesResource} from '@app/core/resource/messages.resource';
import {PaginatedList} from '@app/core/components/pagination/paginated-list.abstract';
import {MessageDto} from '@app/core/resource-dto/messages';
import Message = MessageDto.Message;
import {PageModel} from '@app/core/components/pagination/page.model';
import MessagePageResponse = MessageDto.MessagePageResponse;
import {MessagesRowModel} from '@app/messages/list/messages/messages-row.model';
import MessagePageRequest = MessageDto.MessagePageRequest;
import {takeUntil} from 'rxjs/operators';
import MessageRequest = MessageDto.MessageRequest;
import {ToastrService} from 'ngx-toastr';
import {NavbarBadgeService} from '@app/core/services/navbar-badge.service';

@Injectable({
  providedIn: 'root'
})
export class MessagesService extends PaginatedList<any, Message> implements OnDestroy {

  constructor(
    private resource: MessagesResource,
    private toastr: ToastrService,
    private statsService: NavbarBadgeService
  ) {
    super();
    this.loadMessages();
  }

  public addMessage(request: MessageRequest) {
    this.resource.add(request).then(() => {
      this.toastr.success('Teavituse loomine õnnestus');
    }).catch(() => {
      this.toastr.error('Teavituse loomine ebaõnnestus');
    }).finally(() => {
      this.setFilter({});
      this.statsService.updateBadge();
    });
  }

  public updateMessage(request: MessageRequest, messageId: number) {
    this.resource.update(request, null, {messageId}).then(() => {
      this.toastr.success('Teavituse muutmine õnnestus');
    }).catch(() => {
      this.toastr.error('Teavituse muutmine ebaõnnestus');
    }).finally(() => {
      this.setFilter({});
      this.statsService.updateBadge();
    });
  }

  public loadMessages() {
    this.query$.pipe(takeUntil(this.ngDestroy)).subscribe((request: MessagePageRequest) => {
      this.query(request);
    });
  }

  protected mapQuery([filter, page]: [any, PageModel]): any {
    const params = new MessagePageRequest();
    params.offset = !!page?.offset ? page.offset : 0;

    if (!!page?.limit) {
      params.limit = page.limit;
    }

    if (page.sort && page.sort.sorts.length > 0) {
      page.sort.sorts.map(val => {
        let sortField = val.prop.toString().toUpperCase();

        if (sortField === 'CREATEDAT') {
          sortField = 'CREATED_AT';
        }
        if (sortField === 'CREATEDBY') {
          sortField = 'CREATED_BY';
        }

        params.sort = val.dir.toString().toUpperCase();
        params.sortField = sortField;
      });
    }

    return params;
  }

  protected query(request: any): void {
    this.resource.list(request).then((res: MessagePageResponse) => {
      const rows = res.data.map(MessagesRowModel.of);

      this.tableSource.next(
        this.currentTable
          .setCount(res.metadata.count)
          .setOffset(res.metadata.page)
          .setRows(rows)
      );
    });
  }
}
