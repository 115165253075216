<div class="block-tabs-wrapper">
    <div class="block-tabs" [class.remove-borders]="!blockOpen || tabs.length == 1">
        <button *ngFor="let tab of tabs" (click)="changeTab(tab.name)" [class.active]="activeTab === tab.name">
          {{tab.name}} <span *ngIf="tab.label" class="tab-badge">{{tab.badge}}</span>
        </button>
        <button *ngIf="isCollapsible" class="block-collapsible" (click)="collapseBlock()">
            <span class="icon arrow icon-dark icon-xs" [class.rotate-180]="blockOpen"></span>
        </button>
    </div>
    <div>
      <button *ngIf="modalButton" class="btn btn-sm btn-outline-dark" (click)="secondaryClick()">
        {{modalButton.name}}
      </button>
      <button *ngIf="actionButton" class="btn btn-primary btn-sm ml-2" (click)="click()">
        {{actionButton.name}}
      </button>
    </div>
</div>
