import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-detail-block-tab',
  templateUrl: './detail-block-tab.component.html',
  styleUrls: ['./detail-block-tab.component.scss']
})
export class DetailBlockTabComponent {

  @Input()
  public tabs;

  @Input()
  public isCollapsible;

  @Input()
  public actionButton;

  @Input()
  public modalButton;

  @Input()
  public activeTab;

  @Input()
  public blockOpen;

  @Output()
  public readonly tabSelected: EventEmitter<any> = new EventEmitter();

  @Output()
  public readonly toggleBlock: EventEmitter<any> = new EventEmitter();

  @Output()
  public readonly clickButton: EventEmitter<any> = new EventEmitter();

  @Output()
  public readonly secondaryButton: EventEmitter<any> = new EventEmitter();

  public click(): void {
    this.clickButton.emit();
  }

  public secondaryClick(): void {
    this.secondaryButton.emit();
  }

  public changeTab(tab): void {
    this.tabSelected.emit(tab);
  }

  public collapseBlock(): void {
    this.toggleBlock.emit();
  }
}
