import {PipeTransform, Pipe, OnDestroy} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';
import {ClassifierService} from '@app/core/services/classifier.service';

@Pipe({name: 'classifierObservable'})
export class ClassifierLabelObservablePipe implements PipeTransform, OnDestroy {

    private readonly ngDestroy = new Subject<void>();

    public constructor(private classifierService: ClassifierService) {}

    public ngOnDestroy(): void {
        this.ngDestroy.next();
        this.ngDestroy.complete();
    }

    public transform(code: any, classifier: any): Observable<string> {
        return this.classifierService.getClassifierValueByCodeObservable(classifier, code)
            .pipe(takeUntil(this.ngDestroy), map(x => x?.name || code));
    }
}
