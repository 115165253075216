import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild} from '@angular/core';
import {PopoverDirective} from 'ngx-bootstrap/popover';
import {PopoverEvent} from '@app/core/components/popover/popover-event';

@Component({
    selector: 'app-popover',
    templateUrl: './popover.component.html',
})
export class PopoverComponent implements OnChanges {

    @ViewChild('popover', {read: PopoverDirective})
    public popover: PopoverDirective;

    @Output()
    public onSave: EventEmitter<PopoverEvent> = new EventEmitter();

    @Input()
    public placement: PopoverDirective['placement'];

    @Input()
    public type: 'input' | 'textarea';

    @Input()
    public placeholder = '';

    @Input()
    public name: string;

    @Input()
    public value: any;

    public model: any;

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes?.value?.previousValue === this.value) return;

        this.model = this.value;
    }

    public cancel(): void {
        this.model = this.value;
        this.popover.hide();
    }

    public save(): void {
        this.onSave.emit({
            name: this.name,
            value: this.model
        });
        this.popover.hide();
    }
}
